import React from 'react';

import Box from '@mui/material/Box';

import {TraceToolCall} from '@wildix/wim-voicebots-client';

import ContentTitle from './content/ContentTitle';
import ContentValue from './content/ContentValue';
import ContextJsonOrTextValue from './content/ContextJsonOrTextValue';

interface TraceToolSegmentContentProps {
  tool: TraceToolCall;
}

function formatHeadersToString(headers: Record<string, string>): string {
  return Object.entries(headers)
    .map(([key, value]) => `${key}: ${value}`)
    .join('\n');
}

export default function TraceToolSegmentContent(props: TraceToolSegmentContentProps) {
  const {tool} = props;

  return (
    <Box>
      {tool.function && (
        <>
          <ContentTitle>Name</ContentTitle>
          <ContentValue>{tool.function.name}</ContentValue>
          {tool.function.args && (
            <>
              <ContentTitle>Arguments</ContentTitle>
              <ContextJsonOrTextValue text={tool.function.args} />
            </>
          )}
          {tool.function.request && (
            <>
              <ContentTitle>Url</ContentTitle>
              <ContentValue>{tool.function.request.url}</ContentValue>
              {tool.function.request.headers && (
                <>
                  <ContentTitle>Headers</ContentTitle>
                  <ContentValue>{formatHeadersToString(tool.function.request.headers)}</ContentValue>
                </>
              )}
            </>
          )}
        </>
      )}
      {tool.transfer && (
        <>
          <ContentTitle>Context</ContentTitle>
          <ContentValue>{tool.transfer.context}</ContentValue>
          <ContentTitle>Extension</ContentTitle>
          <ContentValue>{tool.transfer.extension}</ContentValue>
        </>
      )}
      {tool.delegation && (
        <>
          <ContentTitle>Bot Id</ContentTitle>
          <ContentValue>{tool.delegation.id}</ContentValue>
        </>
      )}
    </Box>
  );
}
