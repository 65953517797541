import React from 'react';

import {Box, CircularProgress} from '@mui/material';
import Button from '@mui/material/Button';

import {TraceSegment} from '@wildix/wim-voicebots-client';

import {sortBy} from 'lodash';

import ArrowRightIconWeb20 from '../../../../base/icons/ArrowRightIconWeb20';
import formatDurationFromMillis from '../../helpers/formatDurationFromMillis';
import formatSegmentDescription from '../../helpers/formatSegmentDescription';

interface SegmentProps {
  segment: TraceSegment;
}

function SegmentStatus(props: SegmentProps) {
  const {segment} = props;
  let component = null;

  if (segment.status === 'pending') {
    component = <CircularProgress size={12} />;
  } else if (segment.status === 'complete' && segment.duration) {
    component = formatDurationFromMillis(segment.duration);
  }

  return <Box sx={{fontSize: '14px', fontWeight: '400', ml: 2}}>{component}</Box>;
}

interface TraceActivityContentProps {
  segments: TraceSegment[];
  onClick: (segmentId: string) => void;
}

export default function TraceActivitySegmentsContent(props: TraceActivityContentProps) {
  const {segments, onClick} = props;
  const segmentsSorted = sortBy(segments, 'start');

  if (segments.length === 0) {
    return null;
  }

  return (
    <Box>
      <Box sx={{mt: 3}}>
        <Box sx={{fontSize: '13px', fontWeight: '500'}}>Pipeline</Box>
      </Box>
      {segmentsSorted.map((segment, index) => {
        const description = formatSegmentDescription(segment);

        return (
          <Box key={segment.id} sx={{mt: 1.5}}>
            <Button
              variant="text"
              onClick={() => onClick(segment.id)}
              sx={{
                mt: 1,
                padding: '12px',
                borderRadius: '6px',
                backgroundColor: '#FAFAFA',
                color: 'black',
                textTransform: 'none',
                width: '100%',
                textAlign: 'left',
                justifyContent: 'flex-start',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}>
              <Box
                sx={{
                  flex: '1',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <Box sx={{fontSize: '14px', fontWeight: '400'}}>
                  {index + 1}. {description.title}
                </Box>
                <SegmentStatus segment={segment} />
              </Box>
              <ArrowRightIconWeb20 />
            </Button>
          </Box>
        );
      })}
    </Box>
  );
}
