import React, {useMemo} from 'react';

import Box from '@mui/material/Box';

import ContentValue from './ContentValue';

import ReactJson from '@microlink/react-json-view';

interface ContextJsonOrTextProps {
  text: string;
}

export default function ContextJsonOrTextValue(props: ContextJsonOrTextProps) {
  const {text} = props;
  const json = useMemo(() => {
    try {
      return JSON.parse(text);
    } catch (jsonParseError) {
      // Ignore
    }
  }, [text]);

  if (json) {
    return (
      <Box
        sx={{
          marginTop: '8px',
          padding: '12px',
          borderRadius: '6px',
          fontFamily: 'monospace',
          fontSize: '13px',
          backgroundColor: '#FAFAFA',
          overflow: 'auto',
        }}>
        <ReactJson src={json} name={null} iconStyle="circle" displayObjectSize={false} displayDataTypes={false} />
      </Box>
    );
  } else {
    return <ContentValue>{text}</ContentValue>;
  }
}
