import React from 'react';

import {Box} from '@mui/material';

import {Trace} from '@wildix/wim-voicebots-client';

import TraceTimelineActivity from './TraceTimelineActivity';
import TraceTimelineDistance from './TraceTimelineDistance';

interface TraceTimelineProps {
  trace: Trace | undefined;
  activityId: string | undefined;
  onSelect: (activityId: string) => void;
}

export default function TraceTimeline(props: TraceTimelineProps) {
  const {trace, activityId, onSelect} = props;

  const components = [];

  if (trace) {
    for (let index = 0; index < trace.activities.length; index++) {
      const activity = trace.activities[index];
      const nextActivity = trace.activities[index + 1];

      components.push(
        <TraceTimelineActivity
          key={activity.id}
          selected={activityId === activity.id}
          activity={activity}
          onClick={() => onSelect(activity.id)}
        />,
      );

      if (nextActivity) {
        components.push(
          <TraceTimelineDistance key={`${activity.id}_distance`} activityA={nextActivity} activityB={activity} />,
        );
      }
    }
  }

  return (
    <Box sx={{width: '320px', borderRight: '1px solid #090F160F', overflow: 'auto', paddingBottom: '40px'}}>
      <Box sx={{padding: '8px 18px', fontSize: '14px', fontWeight: '500'}}>Trace</Box>
      {components}
    </Box>
  );
}
