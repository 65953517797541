import React from 'react';
import {Controller, FieldValues, RegisterOptions, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {get} from 'lodash';

import {BotFormData} from '../../../../types';

interface VoiceBotTextFieldFieldProps {
  form: UseFormReturn<BotFormData>;
  path: string;
  label: string;
  placeholder?: string;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
  rules?: RegisterOptions<FieldValues, string>;
}

export default function VoiceBotTextFieldField(props: VoiceBotTextFieldFieldProps) {
  const {form, path, label, placeholder, rules, disabled, required = true} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, path);

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          autoComplete="off"
          inputRef={ref}
          name={name}
          size="small"
          required={required}
          fullWidth
          label={label}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value || ''}
          error={!!error}
          helperText={error ? String(error.message || error.type) : undefined}
        />
      )}
      disabled={disabled}
      name={path as any}
      rules={rules as any}
    />
  );
}
