import React from 'react';

import {Box} from '@mui/material';

import {TraceActivity} from '@wildix/wim-voicebots-client';

import formatDurationFromMillis from '../../helpers/formatDurationFromMillis';

interface TraceTimelineDistanceProps {
  activityA: TraceActivity;
  activityB: TraceActivity;
}

export default function TraceTimelineDistance(props: TraceTimelineDistanceProps) {
  const {activityA, activityB} = props;

  const duration = activityB.start - activityA.start;

  if (duration > 0) {
    return <Box sx={{padding: '2px 18px', fontSize: '10px', color: '#666'}}>{formatDurationFromMillis(duration)}</Box>;
  } else {
    return <Box sx={{height: '6px'}} />;
  }
}
