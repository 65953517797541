import {useMutation} from 'react-query';
import {UseMutationOptions} from 'react-query/types/react/types';

import {getToken} from '../../base/helpers/getWimConsoleSettings';
import {WIM_API} from '../../env';
import useConsoleIntegrationInvalidateTrigger from './useConsoleIntegrationInvalidateTrigger';

const deleteIntegration = async (integrationId: string) => {
  const token = await getToken();
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const res = await fetch(`${WIM_API}/console/integrations?integrationId=${integrationId}`, options);
  const json = await res.json();

  if (res.ok) {
    // Wait additional 5 seconds to ensure that integration was removed (API limitation)
    const wait = new Promise((resolve) => {
      setTimeout(resolve, 5000);
    });
    await wait;
  }

  return json;
};

export default function useConsoleIntegrationDeleteMutation(options: UseMutationOptions<any, any, any, any> = {}) {
  const invalidateIntegrations = useConsoleIntegrationInvalidateTrigger();

  return useMutation({
    ...options,
    mutationFn: deleteIntegration,
    onSuccess: (...args) => {
      if (options?.onSuccess) {
        options.onSuccess(...args);
      }

      invalidateIntegrations();
    },
    onError: (...args) => {
      if (options?.onError) {
        options.onError(...args);
      }

      invalidateIntegrations();
    },
  });
}
