import React from 'react';
import {createRoot} from 'react-dom/client';

import {CssBaseline} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import Console from './Console';

const theme = createTheme({});
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Console />
      </ThemeProvider>
    </>
  </React.StrictMode>,
);
