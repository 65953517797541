import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';

import {Integration} from '../../../base/types';
import MicrosoftPresenceIntegrationUsersSettings from './MicrosoftPresenceIntegrationUsersSettings';

import styled from '@emotion/styled';

type props = {
  integration: Integration;
};

const SettingsContainer = styled('div')({
  marginTop: '16px',
});

const StyledAccordion = styled(Accordion)({
  borderTopWidth: '0',
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  '&:nth-of-type(1)': {
    borderTopWidth: '1px',
  },
  '&.Mui-expanded': {
    borderTopWidth: '1px',
    borderRadius: '8px',
  },
});

export default function MicrosoftPresenceIntegrationSettings({integration}: props) {
  return (
    <SettingsContainer>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <b>Advanced users configuration</b>
        </AccordionSummary>
        <AccordionDetails>
          <MicrosoftPresenceIntegrationUsersSettings integration={integration} />
        </AccordionDetails>
      </StyledAccordion>
    </SettingsContainer>
  );
}
