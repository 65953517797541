import {WimConsoleSettings} from '../types';

export const APP_SETTINGS: WimConsoleSettings = {
  authorizer: () => {
    const params = new URLSearchParams(window.location.search);
    const jwt = params.get('jwt');
    let token;

    if (jwt && jwt.length > 0) {
      token = jwt;
      localStorage.setItem('jwt', jwt);
    } else {
      token = localStorage.getItem('jwt');
    }

    if (!token) {
      throw new Error('Not authorized.');
    }

    return Promise.resolve(token);
  },
  baseRouteName: '/',
};

export function getToken(): Promise<string> {
  return APP_SETTINGS.authorizer();
}
