import React, {useEffect, useState} from 'react';

import {Box, IconButton, Modal, Stack} from '@mui/material';

import {Trace} from '@wildix/wim-voicebots-client';

import CloseIcon18 from '../../../../base/icons/CloseIcon18';
import TraceContent from './TraceContent';
import TraceTimeline from './TraceTimeline';

interface TracesViewerProps {
  trace?: Trace;
  onClose?: () => void;
}

export default function TracesViewer(props: TracesViewerProps) {
  const {trace, onClose} = props;
  const [open, setOpen] = useState(false);
  // const [trace, setTrace] = useState<Trace | undefined>();

  useEffect(() => {
    setOpen(trace !== undefined);
  }, [trace]);

  const [selectedActivityId, setSelectedActivityId] = useState<string | undefined>();

  const onActivitySelected = (activityId: string) => {
    setSelectedActivityId(activityId);
  };

  return (
    <Modal
      sx={{
        top: '20px',
        right: '20px',
        bottom: '20px',
        transform: 'none',
        margin: '0',
        left: 'auto',
      }}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Stack
        flexDirection="column"
        overflow="hidden"
        width="1024px"
        height="100%"
        sx={{backgroundColor: '#fff'}}
        borderRadius="12px">
        <Box sx={{borderBottom: '1px solid #090F160F', display: 'flex', alignItems: 'center', padding: '8px 18px'}}>
          <Box sx={{fontSize: '16px', flex: '1', fontWeight: '500'}}>{trace?.session?.sessionId}</Box>
          <Box>
            <IconButton onClick={onClose}>
              <CloseIcon18 />
            </IconButton>
          </Box>
        </Box>
        <Stack flexDirection="row" flex="1" overflow="hidden">
          <TraceTimeline trace={trace} activityId={selectedActivityId} onSelect={onActivitySelected} />
          <TraceContent trace={trace} activityId={selectedActivityId} />
        </Stack>
      </Stack>
    </Modal>
  );
}
