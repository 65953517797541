import {useMutation, useQueryClient} from 'react-query';
import {UseMutationOptions} from 'react-query/types/react/types';

import {DeleteVoiceBotCommand, DeleteVoiceBotOutput} from '@wildix/wim-voicebots-client';

import getWimVoiceBotsClient from '../../../base/helpers/getWimVoiceBotsClient';

export default function useDeleteVoiceBotMutation(
  options: UseMutationOptions<DeleteVoiceBotOutput, unknown, string> = {},
) {
  const qc = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (botId: string) => getWimVoiceBotsClient().send(new DeleteVoiceBotCommand({botId})),
    onSuccess: (...args) => {
      if (options?.onSuccess) {
        options.onSuccess(...args);
      }

      qc.resetQueries('voicebots', {exact: true});
    },
  });
}
