import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import {VoiceBotFunctionIntegrationMethod} from '@wildix/wim-voicebots-client';

import {get} from 'lodash';

import {BotFormData} from '../../../types';

const methods = [
  VoiceBotFunctionIntegrationMethod.GET,
  VoiceBotFunctionIntegrationMethod.POST,
  VoiceBotFunctionIntegrationMethod.PUT,
  VoiceBotFunctionIntegrationMethod.PATCH,
  VoiceBotFunctionIntegrationMethod.DELETE,
];

interface FunctionIntegrationWehookMethodFieldProps {
  form: UseFormReturn<BotFormData>;
  path: string;
}

export default function FunctionIntegrationWebhookMethodField(props: FunctionIntegrationWehookMethodFieldProps) {
  const {form, path} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, path);

  return (
    <Controller
      control={control}
      name={path as any}
      rules={{required: true}}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <FormControl sx={{width: '140px'}} size="small" error={!!error} disabled={isSubmitting} required>
          <InputLabel id={`${name}-label`}>Method</InputLabel>
          <Select
            labelId={`${name}-label`}
            id={name}
            value={value || 'post'}
            label="Method"
            onChange={(event) => {
              onChange(event);
              trigger(name);
            }}
            onBlur={onBlur}
            inputRef={ref}>
            {methods.map((method) => (
              <MenuItem key={method} value={method}>
                {method.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
