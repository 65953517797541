import {Dispatch, SetStateAction} from 'react';

export enum IntegrationService {
  GOOGLE_CALENDAR = 'google_calendar',
  MICROSOFT_CALENDAR = 'microsoft_calendar',
  SALESFORCE_COMMUNITY = 'salesforce_community',
  IFRAME_INTEGRATION = 'iframe_integration',
  MICROSOFT_PRESENCE = 'microsoft_presence',
  WEBHOOK = 'webhook',
  HUBSPOT_BACKEND = 'hubspot_backend',
  SALESFORCE_BACKEND = 'salesforce_backend',
  SALESFORCE_TELEPHONY = 'salesforce_telephony',
  GONG = 'gong',
}

export enum IntegrationStatusType {
  INSTALLING = 'installing',
  BROKEN = 'broken',
  ENABLED = 'enabled',
  WARNING = 'warning',
  HIDDEN = 'hidden',
}

export interface IntegrationsResponse {
  allowed: string[];
  installed: Integration[];
  webhook: any;
}

export type IntegrationLogField =
  | 'email'
  | 'status'
  | 'resourceId'
  | 'subscriptionSubType'
  | 'warningCode'
  | 'errorCode'
  | 'error'
  | 'pbxDomains'
  | 'until';

export type ProblemInfo = {
  place: string;
  code: string;
} & Record<IntegrationLogField, string>;

export type IntegrationLog = {
  integrationId: string;
  eventType: string;
  createdAt: string;
} & Record<IntegrationLogField, string>;

export type ErrorFormatterWithParam = <T extends Partial<IntegrationLog>>(log: T) => string;
export type ErrorFormatterWithoutParam = () => string;
export type ErrorFormatter = ErrorFormatterWithParam | ErrorFormatterWithoutParam;

export interface Integration {
  integrationId: string;
  externalId: string;
  templateId: string;
  blendId: string;
  service: IntegrationService;
  status?: IntegrationStatusType;
  errorInfo?: ProblemInfo;
  warningInfo?: ProblemInfo;
  data: {
    baseUrl?: string;
    timeZone?: string;
    account?: {
      zoneinfo?: string;
      [key: string]: any;
    };

    [key: string]: any;
  };
}

export type IntegrationSetter = Dispatch<SetStateAction<Integration[]>>;

export interface WimConsoleSettings {
  authorizer: () => Promise<string>;
  baseRouteName: string;
}
