import {useQuery} from 'react-query';

import {GetTraceCommand, Trace, TraceSession} from '@wildix/wim-voicebots-client';

import getWimVoiceBotsClient from '../../../base/helpers/getWimVoiceBotsClient';

function orderTrace(trace: Trace): Trace {
  return {
    ...trace,
    activities: trace.activities.sort((a, b) => b.start - a.start), // descending order
    segments: trace.segments.sort((a, b) => b.start - a.start), // descending order
  };
}

export default function useTraceQuery(session: TraceSession | undefined) {
  const traceQuery = useQuery(
    `vb:traces:${session?.sessionId}`,
    async () => {
      const response = await getWimVoiceBotsClient().send(new GetTraceCommand({sessionId: session!.sessionId}));

      return {trace: orderTrace(response.trace)};
    },
    {
      enabled: Boolean(session?.sessionId),
      retry: false,
    },
  );

  return traceQuery;
}
