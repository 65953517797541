import React, {useState} from 'react';

import {Checkbox, FormControlLabel} from '@mui/material';

import {Integration} from '../../../base/types';
import useConsoleIntegrationPatchMutation from '../../hooks/useConsoleIntegrationPatchMutation';

type props = {
  integration: Integration;
};

export default function MicrosoftIntegrationSettings(props: props) {
  const {
    integration: {integrationId, data},
  } = props;
  const [hidePublicEventSummary, setHidePublicEventSummary] = useState(Boolean(data?.settings?.hidePublicEventSummary));

  const onSuccess = (response: any) => {
    const {result} = response;

    setHidePublicEventSummary(result.data?.settings?.hidePublicEventSummary);
  };

  const {isLoading, mutate} = useConsoleIntegrationPatchMutation({onSuccess}, true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    mutate({
      integrationId,
      data: {...data, settings: {hidePublicEventSummary: event.target.checked}},
    });
    setHidePublicEventSummary(event.target.checked);
  };

  return (
    <FormControlLabel
      control={<Checkbox disabled={isLoading} onChange={handleChange} checked={hidePublicEventSummary} />}
      label="Display status message for public events"
      aria-label="Checkbox to display status message for public events"
    />
  );
}
