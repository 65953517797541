import {IntegrationService} from '../../base/types';

export interface Microsoft365InstallData {
  tenant: string;
}

interface Settings {
  settings: {
    hidePublicEventSummary: boolean;
  };
}

export interface Microsoft365InstallProps {
  service: IntegrationService.MICROSOFT_CALENDAR;
  data: Microsoft365InstallData;
}

export interface Microsoft365UpdateProps {
  integrationId: string;
  status?: 'enabled' | 'disabled';
  data?: Settings;
}

export enum MicrosoftIntegrationPath {
  base = '/microsoft/calendar',
  callback = '/microsoft/calendar/callback',
}
