import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, IconButton, Stack, Typography} from '@mui/material';

import RemoveIcon20 from '../../../../../base/icons/RemoveIcon20';
import {BotFormData} from '../../../types';
import VoiceBotTextFieldField from './base/VoiceBotTextField';
import TransferDescriptionField from './TransferDescriptionField';
import TransferPipelineField from './TransferPipelineField';
import TransferVariantField, {VariantTypes} from './TransferVariantField';

interface TransferToolProps {
  position: number;
  index: number;
  form: UseFormReturn<BotFormData>;
  path: string;
}

export default function TransferTool(props: TransferToolProps) {
  const {position, index, form, path} = props;
  const {watch, getValues, formState} = form;
  const {isSubmitting} = formState;

  const embeddedTools = watch('endpoint.llm.embeddedTools');

  const variantPath = `${path}.parameters.variant`;
  const variant: string = watch(variantPath as any) || VariantTypes.PREDEFINED;

  const onDeleteClick = () => {
    const state = getValues();
    const newState: BotFormData = {
      ...state,
      endpoint: {
        llm: {
          ...state.endpoint.llm!,
          embeddedTools: (embeddedTools || []).filter((item, itemIndex) => itemIndex !== index),
        },
      },
    };

    form.reset(newState);
  };

  return (
    <Box sx={{pt: 1}}>
      <Stack flexDirection="row" alignItems="center">
        <Box>{position}.</Box>
        <Box ml={1}>Transfer</Box>
        <Box flex="1" sx={{height: '1px', background: '#CCC', ml: 2}} />

        <Box ml={1}>
          <IconButton sx={{width: '32px', height: '32px', padding: '6px'}} onClick={onDeleteClick}>
            <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
        </Box>
      </Stack>
      <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
        Allows to transfer calls to certain extensions in the dialplan, allowing complex inquiries to be handled by a
        human representative for better customer satisfaction. By setting a custom description, you can guide the
        voicebot to make better decisions about when to transfer the call.
      </Typography>
      <Box pt={2} maxWidth="75%">
        <Box>
          <TransferDescriptionField form={form} path={`${path}.parameters.description`} />
        </Box>
        <Box mt={2} sx={{width: '50%'}}>
          <VoiceBotTextFieldField
            form={form}
            path={`${path}.parameters.context`}
            label="Context"
            placeholder="users"
            rules={{required: true}}
            disabled={isSubmitting}
          />
        </Box>
        <Box mt={2}>
          <TransferVariantField form={form} path={variantPath} />
        </Box>
        {variant === VariantTypes.PREDEFINED && (
          <Box mt={2} sx={{width: '50%'}}>
            <VoiceBotTextFieldField
              form={form}
              path={`${path}.parameters.extension`}
              label="Exension"
              placeholder="100"
              rules={{required: true}}
              disabled={isSubmitting}
            />
          </Box>
        )}
        <Box mt={2}>
          <TransferPipelineField form={form} path={`${path}.parameters.pipeline`} />
        </Box>
      </Box>
    </Box>
  );
}
