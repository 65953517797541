export default function formatToolCallResult(result: string | undefined): string | undefined | object {
  if (result) {
    try {
      result = JSON.parse(result);
    } catch (jsonParseError) {
      // ignore
    }
  } else {
    result = undefined;
  }

  return result;
}
