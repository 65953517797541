import {WebhookEventGroup} from './types';

export enum WebhooksIntegrationPath {
  base = '/webhooks',
  install = '/webhooks/install',
  edit = '/webhooks/:integrationId',
}
export const WEBHOOK_EVENTS: WebhookEventGroup[] = [
  {
    key: 'calls',
    label: 'Call events',
    events: [
      {key: 'call:live:progress', description: 'Triggered when an ongoing call starts or gets updated.'},
      {key: 'call:live:completed', description: 'Triggered when a call ends.'},
      {key: 'call:live:interrupted', description: 'Triggered when a call ends due to an error.'},
      {
        key: 'call:live:transcription',
        description: 'Triggered when a new transcription segment for the call is available.',
      },
      {key: 'call:start', deprecated: true, description: 'Triggered when a call starts.'},
      {key: 'call:update', deprecated: true, description: 'Triggered when a call gets updated.'},
      {key: 'call:end', deprecated: true, description: 'Triggered when a call ends.'},
    ],
  },
  {
    key: 'conferences',
    label: 'Conference events',
    events: [
      {key: 'conference:live:progress', description: 'Triggered when an ongoing conference starts or gets updated.'},
      {key: 'conference:live:join', description: 'Triggered when a user joins the conference.'},
      {key: 'conference:live:leave', description: 'Triggered when a user leaves the conference.'},
      {key: 'conference:live:completed', description: 'Triggered when a conference ends.'},
      {key: 'conference:live:interrupted', description: 'Triggered when a conference ends due to an error.'},
      {
        key: 'conference:live:transcription',
        description: 'Triggered when a new transcription segment for the conference is available.',
      },
    ],
  },
  {
    key: 'chats',
    label: 'Chat events',
    events: [
      {key: 'chat:channel:created', description: 'Triggered when a new chat channel is created.'},
      {key: 'chat:channel:updated', description: 'Triggered when a chat channel is updated.'},
      {key: 'chat:member:added', description: 'Triggered when a new member is added to a chat channel.'},
      {key: 'chat:member:removed', description: 'Triggered when a member is removed from a chat channel.'},
      {key: 'chat:message:new', description: 'Triggered when a new message is sent in a chat channel.'},
      {key: 'chat:message:updated', description: 'Triggered when a message is updated in a chat channel.'},
      {key: 'chat:message:deleted', description: 'Triggered when a message is deleted from a chat channel.'},
      {key: 'chat:reaction:new', description: 'Triggered when a user reacts to a message.'},
      {key: 'chat:reaction:deleted', description: 'Triggered when a user removes a reaction from a message.'},
      {key: 'chat:live:progress', description: 'Triggered when a live chat session starts or gets updated.'},
      {key: 'chat:live:completed', description: 'Triggered when a live chat session ends.'},
      {key: 'chat:live:interrupted', description: 'Triggered when a live chat session ends due to an error.'},
      {key: 'chat:manager:missed', description: 'Triggered when a chat detects a missed manager.'},
    ],
  },
  {
    key: 'analytics',
    label: 'Analytics events',
    events: [
      {key: 'call:completed', description: 'Triggered when a call ends and all related information is stored.'},
      {
        key: 'call:transcription:completed',
        description: 'Triggered when a call ends and all related information is stored',
      },
      {
        key: 'call:transcription:text:completed',
        description: 'Triggered when a call ends and all related information is stored.',
      },
      {key: 'call:summary:completed', description: 'Triggered when a call ends and all related information is stored.'},
      {
        key: 'conference:completed',
        description: 'Triggered when a conference ends and all related information is stored.',
      },
      {
        key: 'conference:transcription:completed',
        description: 'Triggered when a conference ends and all related information is stored.',
      },
      {
        key: 'conference:transcription:text:completed',
        description: 'Triggered when a conference ends and all related information is stored.',
      },
      {
        key: 'conference:summary:completed',
        description: 'Triggered when a conference ends and all related information is stored.',
      },
      {key: 'chat:completed', description: 'Triggered when a chat session ends and all related information is stored.'},
    ],
  },
  {
    key: 'presence',
    label: 'Presence events',
    events: [
      {key: 'presence:user', description: 'Triggered when a user changes their presence status.'},
      {key: 'presence:telephony', description: 'Triggered when a user changes their "On the call" status.'},
      {
        key: 'presence:conference',
        description: 'Triggered when a user joins or leaves a conference, updating their presence status.',
      },
    ],
  },
];
