import React from 'react';

import {Box} from '@mui/material';

import {TraceActivity, TraceActivityStatus} from '@wildix/wim-voicebots-client';

import ContentTitle from './content/ContentTitle';
import ContentValue from './content/ContentValue';

interface TraceActivityResultContentProps {
  activity: TraceActivity;
}

export default function TraceActivityResultContent(props: TraceActivityResultContentProps) {
  const {activity} = props;
  const {status, result} = activity;

  if (status === TraceActivityStatus.CANCELLED) {
    return (
      <Box>
        <ContentTitle>Result</ContentTitle>
        <ContentValue sx={{fontStyle: 'italic'}}>Cancelled</ContentValue>
      </Box>
    );
  }

  if (result?.text) {
    return (
      <Box>
        <ContentTitle>Result</ContentTitle>
        <ContentValue>{result.text}</ContentValue>
      </Box>
    );
  } else if (result?.error) {
    return (
      <Box>
        <ContentTitle>Error</ContentTitle>
        <ContentValue>{result.error.message}</ContentValue>
      </Box>
    );
  }

  return null;
}
