import React from 'react';

import {SvgIcon} from '@mui/material';
import {SvgIconProps} from '@mui/material/SvgIcon/SvgIcon';

export default function BackIconWeb24(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M14.1138 17.7554L9.08008 12.8423C8.95947 12.7217 8.896 12.5757 8.896 12.417C8.896 12.252 8.96582 12.0933 9.08643 11.9917L14.1138 7.07227C14.2217 6.96436 14.3613 6.90088 14.5264 6.90088C14.8564 6.90088 15.104 7.15479 15.104 7.48486C15.104 7.63721 15.0405 7.78955 14.939 7.89746L10.3179 12.417L14.939 16.9365C15.0405 17.0444 15.104 17.1904 15.104 17.3491C15.104 17.6792 14.8564 17.9268 14.5264 17.9268C14.3613 17.9268 14.2217 17.8633 14.1138 17.7554Z"
        fill="inherit"
      />
    </SvgIcon>
  );
}
