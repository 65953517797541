import {useMutation} from 'react-query';
import {UseMutationOptions} from 'react-query/types/react/types';

import {getToken} from '../../base/helpers/getWimConsoleSettings';
import {WIM_API} from '../../env';
import {IntegrationInstallProps} from '../types';
import useConsoleIntegrationInvalidateTrigger from './useConsoleIntegrationInvalidateTrigger';

export async function createIntegration({service, data}: IntegrationInstallProps): Promise<any> {
  const token = await getToken();
  const options: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({service, data}),
  };

  return fetch(`${WIM_API}/console/integrations`, options).then(async (res) => {
    if (!res.ok) {
      let error: any;

      try {
        error = await res.json();
      } catch (e) {
        throw new Error(res.statusText);
      }

      if (error.message) {
        throw new Error(error.message);
      } else {
        throw new Error(res.statusText);
      }
    }

    return res.json();
  });
}

export default function useIntegrationInstallationMutation(options: UseMutationOptions<any, any, any, any>) {
  const invalidateIntegrations = useConsoleIntegrationInvalidateTrigger();

  return useMutation({
    ...options,
    mutationFn: createIntegration,
    onSuccess: (...args) => {
      if (options?.onSuccess) {
        options.onSuccess(...args);
      }

      invalidateIntegrations();
    },
    onError: (...args) => {
      if (options?.onError) {
        options.onError(...args);
      }

      invalidateIntegrations();
    },
  });
}
