import React from 'react';
import {Controller, FieldErrors, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {VoiceBotEndpoint} from '@wildix/wim-voicebots-client';

import {BotFormData} from '../../types';

interface BotDialogflowCxLocationFieldProps {
  form: UseFormReturn<BotFormData>;
}

export default function BotDialogflowCxLanguageField(props: BotDialogflowCxLocationFieldProps) {
  const {form} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = (errors?.endpoint as FieldErrors<VoiceBotEndpoint.DialogflowCxMember>)?.dialogflowCx?.language;

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          required
          fullWidth
          label="Language"
          placeholder="Language"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name="endpoint.dialogflowCx.language"
      rules={{required: true}}
    />
  );
}
