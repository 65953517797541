import React, {useState} from 'react';

import {Box, Button, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';

import {isEqual} from 'lodash';

import ChipList from '../../../base/components/chipList/ChipList';
import {Integration} from '../../../base/types';
import useConsoleIntegrationPatchMutation from '../../hooks/useConsoleIntegrationPatchMutation';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import {EMAIL_LIST_LIMIT} from '../constants';
import {Settings} from '../types';

type props = {
  integration: Integration;
};

enum ProcessingUsersType {
  ALL = 'all',
  EXCLUDE = 'exclude',
  INCLUDE = 'include',
}

const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

function getActiveList(settings: Settings): string[] {
  return settings.includeEmails?.length ? settings.includeEmails : settings.excludedEmails || [];
}

function getProcessingType(settings: Settings): ProcessingUsersType {
  if (!settings.includeEmails?.length && !settings.excludedEmails?.length) {
    return ProcessingUsersType.ALL;
  }

  return settings.includeEmails?.length ? ProcessingUsersType.INCLUDE : ProcessingUsersType.EXCLUDE;
}

function toNewConfig(emails: string[], processingType: ProcessingUsersType): Settings {
  const ignoreSetting: Settings = {
    excludedEmails: undefined,
    includeEmails: undefined,
  };

  if (processingType === ProcessingUsersType.EXCLUDE) {
    ignoreSetting.excludedEmails = emails;
  } else if (processingType === ProcessingUsersType.INCLUDE) {
    ignoreSetting.includeEmails = emails;
  }

  return ignoreSetting;
}

function isEqualSettings(value: Settings, secondValue: Settings) {
  const keys = ['excludedEmails', 'includeEmails'] as Array<keyof Settings>;

  for (const key of keys) {
    if (!isEqual(value[key], secondValue[key])) {
      return false;
    }
  }

  return true;
}

const hints = {
  [ProcessingUsersType.ALL]:
    'By default, we synchronize the presence of all users for which we found a match in PBX and MS.',
  [ProcessingUsersType.EXCLUDE]:
    'Provide a list of email addresses for users you want to exclude from the synchronization process.',
  [ProcessingUsersType.INCLUDE]:
    'Provide a list of users for whom the synchronization should be enabled. All users that are not in this list will be ignored',
};

export default function MicrosoftPresenceUsersSettings({integration}: props) {
  const {
    integrationId,
    data: {settings = {}},
  } = integration;

  const [emails, setEmails] = useState<string[]>(getActiveList(settings));
  const {refetch} = useConsoleIntegrations();
  const [processingType, setProcessingType] = useState(getProcessingType(settings));

  const isChanged =
    !isEqualSettings(toNewConfig(emails, processingType), settings) &&
    (processingType !== ProcessingUsersType.ALL ? Boolean(emails.length) : true);

  const handleChangeType = (event: React.MouseEvent<HTMLElement>, newProcessingType: ProcessingUsersType) => {
    setProcessingType(newProcessingType);
  };

  const onSuccess = (response: any) => {
    const {result} = response;

    setEmails(getActiveList(result.data?.settings));
    refetch();
  };

  const {isLoading, mutate} = useConsoleIntegrationPatchMutation({onSuccess}, true);

  const emailValidation = (value: string, index: number, count: number) => {
    if (!value.match(emailRegex)) {
      return 'Invalid email';
    }

    if (count + emails.length > EMAIL_LIST_LIMIT) {
      return `You can add up to ${EMAIL_LIST_LIMIT} emails`;
    }
  };

  const handleChanges = (newEmails: string[]) => {
    setEmails(newEmails);
  };

  const handleSubmit = async () => {
    const ignoreSetting: Settings = {
      excludedEmails: undefined,
      includeEmails: undefined,
    };

    if (processingType === ProcessingUsersType.EXCLUDE) {
      ignoreSetting.excludedEmails = emails;
    } else if (processingType === ProcessingUsersType.INCLUDE) {
      ignoreSetting.includeEmails = emails;
    }

    mutate({
      integrationId,
      data: {
        ...integration.data,
        settings: {
          ...integration.data.settings,
          ...toNewConfig(emails, processingType),
        },
      },
    });
  };

  return (
    <div>
      <Typography color="text.primary" sx={{paddingBottom: 2}}>
        Here you can define which users this integration will apply to.
      </Typography>
      <Box mb={2}>
        <ToggleButtonGroup
          disabled={isLoading}
          size="medium"
          exclusive
          value={processingType}
          onChange={handleChangeType}>
          <ToggleButton value={ProcessingUsersType.ALL}>
            <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
              All users
            </Box>
          </ToggleButton>
          <ToggleButton value={ProcessingUsersType.EXCLUDE}>
            <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
              Exclude users
            </Box>
          </ToggleButton>
          <ToggleButton value={ProcessingUsersType.INCLUDE}>
            <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
              Include users
            </Box>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Typography color="text.primary" sx={{paddingBottom: 2}}>
        {' '}
        {hints[processingType]}{' '}
      </Typography>

      {processingType != ProcessingUsersType.ALL ? (
        <ChipList onChange={handleChanges} items={emails} id="emailsList" label="Email" validation={emailValidation} />
      ) : null}

      <Button sx={{mt: 2}} disabled={isLoading || !isChanged} variant="contained" size="medium" onClick={handleSubmit}>
        Save
      </Button>
    </div>
  );
}
