import React from 'react';

import Box from '@mui/material/Box';

import {TraceOAuthInput} from '@wildix/wim-voicebots-client';

import ContentTitle from './content/ContentTitle';
import ContentValue from './content/ContentValue';

interface TraceOauthSegmentContentProps {
  oauth: TraceOAuthInput;
}

export default function TraceOauthSegmentContent(props: TraceOauthSegmentContentProps) {
  const {oauth} = props;

  return (
    <Box>
      {oauth.clientId && (
        <>
          <ContentTitle>Client ID</ContentTitle>
          <ContentValue>{oauth.clientId}</ContentValue>
        </>
      )}
      {oauth.clientSecret && (
        <>
          <ContentTitle>Client Secret</ContentTitle>
          <ContentValue>{oauth.clientSecret}</ContentValue>
        </>
      )}
      {oauth.endpointUrl && (
        <>
          <ContentTitle>Endpoint Url</ContentTitle>
          <ContentValue>{oauth.endpointUrl}</ContentValue>
        </>
      )}
      {oauth.scope && (
        <>
          <ContentTitle>Scope</ContentTitle>
          <ContentValue>{oauth.scope}</ContentValue>
        </>
      )}
    </Box>
  );
}
