import React, {useMemo, useState} from 'react';
import {Link} from 'react-router-dom';

import {Box, Breadcrumbs, Typography} from '@mui/material';

import {TraceSession} from '@wildix/wim-voicebots-client';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import VoiceBotsIcon from '../../../base/icons/VoiceBotsIcon';
import {VOICEBOTS_TITLE} from '../../../texts';
import {VoiceBotsPath} from '../constants';
import useCompletedTracesQuery from '../hooks/useCompletedTracesQuery';
import useOngoingTracesSubscription from '../hooks/useOngoingTracesSubscription';
import useTraceQuery from '../hooks/useTraceQuery';
import TracesTable, {TracesTableContext} from './traces/TracesTable';
import TracesViewer from './traces/TracesViewer';

export default function VoiceBotsTracesScreen() {
  const completedTracesQuery = useCompletedTracesQuery();
  const ongoingTraces = useOngoingTracesSubscription();

  const [activeSession, setActiveSession] = useState<TraceSession | undefined>();

  const context: TracesTableContext = {
    openDetails: (item: TraceSession) => {
      setActiveSession(item);
    },
  };

  const onTraceClose = () => {
    setActiveSession(undefined);
  };

  const traces = useMemo(() => {
    const completedTraces = completedTracesQuery.data?.traces || [];
    const completedTracesIdsMap = completedTraces.reduce(
      (accumulation, trace) => {
        accumulation[trace.sessionId] = true;

        return accumulation;
      },
      {} as Record<string, boolean>,
    );
    const ongoingTracesThatNotInCompleted = ongoingTraces
      .map(({session}) => session)
      .filter(({sessionId}) => !completedTracesIdsMap[sessionId]);
    const result = [...ongoingTracesThatNotInCompleted, ...completedTraces];

    return result;
  }, [ongoingTraces, completedTracesQuery.data]);

  const activeTraceOngoing =
    activeSession && ongoingTraces.find(({session}) => session.sessionId === activeSession?.sessionId);
  const traceQuery = useTraceQuery(activeSession && !activeTraceOngoing ? activeSession : undefined);
  const activeTraceCompleted = traceQuery.data?.trace;

  const activeTrace = activeTraceOngoing || activeTraceCompleted;

  if (completedTracesQuery.isError) {
    return <ApiErrorBox error={completedTracesQuery.error} />;
  }

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={VoiceBotsPath.base}>
          {VOICEBOTS_TITLE}
        </Typography>
        <Typography color="text.primary">Traces</Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '1024px'}}>
        <Box width="96px">
          <VoiceBotsIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5}>
          <Typography color="text.primary" variant="h5">
            Traces
          </Typography>

          <Box sx={{height: 'calc(100vh - 320px)', width: '100%', mt: 3}}>
            <TracesTable traces={traces} context={context} isLoading={completedTracesQuery.isLoading} />
          </Box>
        </Box>
      </Box>
      <TracesViewer trace={activeTrace} onClose={onTraceClose} />
    </Box>
  );
}
