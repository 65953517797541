import React, {useCallback, useState} from 'react';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import IntegrationScreen from '../../../base/components/IntegrationScreen';
import LoadingBox from '../../../base/components/LoadingBox';
import findIntegrationByService from '../../../base/helpers/findIntegrationByService';
import MicrosoftCalendarIcon from '../../../base/icons/MicrosoftCalendarIcon';
import {IntegrationService} from '../../../base/types';
import {MICROSOFT_AZURE_APP_ID, WIM_CONSOLE} from '../../../env';
import {MICROSOFT_CALENDAR_DESCRIPTION, MICROSOFT_CALENDAR_TITLE} from '../../../texts';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import useIntegrationInstallationMutation from '../../hooks/useIntegrationInstallationMutation';
import {MICROSOFT_OAUTH_URL} from '../constants';
import useMicrosoftAdminAuthorization from '../hooks/useMicrosoftAdminAuthorization';
import {MicrosoftIntegrationPath} from '../types';
import MicrosoftIntegrationSettings from './MicrosoftIntegrationSettings';

export default function MicrosoftIntegrationScreen() {
  const [error, setError] = useState('');
  const {
    isLoading: isCILoading,
    isError: isCIError,
    error: ciError,
    data: {installed = []} = {},
  } = useConsoleIntegrations();
  const integration = findIntegrationByService(installed, IntegrationService.MICROSOFT_CALENDAR);

  const {isLoading: isInstallationLoading, mutate} = useIntegrationInstallationMutation({
    onSuccess: (response) => {
      const {success, message} = response;

      if (!success) {
        setError(message);
      }
    },
  });

  const onAuthSuccess = useCallback(
    (tenant: string) => {
      mutate({service: IntegrationService.MICROSOFT_CALENDAR, data: {tenant}});
    },
    [mutate],
  );

  const onAuthFailed = useCallback((error: string, description: string) => {
    setError(`${error}\n\n${description}`);
  }, []);

  const {isLoading: isAuthorizationLoading, authorize} = useMicrosoftAdminAuthorization({
    authorizeUrl: MICROSOFT_OAUTH_URL,
    clientId: MICROSOFT_AZURE_APP_ID,
    redirectUri: `${WIM_CONSOLE}${MicrosoftIntegrationPath.callback}`,
    onSuccess: onAuthSuccess,
    onError: onAuthFailed,
  });

  const onInstallClick = useCallback(() => {
    if (error) {
      setError('');
    }

    authorize();
  }, [authorize, error]);

  if (isCILoading) {
    return <LoadingBox />;
  }

  if (isCIError) {
    return <ApiErrorBox error={ciError} />;
  }

  return (
    <IntegrationScreen
      title={MICROSOFT_CALENDAR_TITLE}
      description={MICROSOFT_CALENDAR_DESCRIPTION}
      icon={<MicrosoftCalendarIcon width={96} height={96} />}
      integration={integration}
      onInstallClick={onInstallClick}
      isInstallLoading={isAuthorizationLoading || isInstallationLoading}
      integrationSettings={integration && <MicrosoftIntegrationSettings integration={integration} />}
      error={error}
    />
  );
}
