import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, IconButton, Stack, Typography} from '@mui/material';

import RemoveIcon20 from '../../../../../base/icons/RemoveIcon20';
import {BotFormData} from '../../../types';
import WaitDescriptionField from './WaitDescriptionField';

interface WaitToolProps {
  position: number;
  index: number;
  form: UseFormReturn<BotFormData>;
  path: string;
}

export default function WaitTool(props: WaitToolProps) {
  const {position, form, path, index} = props;
  const {watch, getValues} = form;
  const embeddedTools = watch('endpoint.llm.embeddedTools');

  const onDeleteClick = () => {
    const state = getValues();
    const newState: BotFormData = {
      ...state,
      endpoint: {
        llm: {
          ...state.endpoint.llm!,
          embeddedTools: (embeddedTools || []).filter((item, itemIndex) => itemIndex !== index),
        },
      },
    };

    form.reset(newState);
  };

  return (
    <Box sx={{pt: 1}}>
      <Stack flexDirection="row" alignItems="center">
        <Box>{position}.</Box>
        <Box ml={1}>Wait</Box>
        <Box flex="1" sx={{height: '1px', background: '#CCC', ml: 2}} />

        <Box ml={1}>
          <IconButton sx={{width: '32px', height: '32px', padding: '6px'}} onClick={onDeleteClick}>
            <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
        </Box>
      </Stack>
      <Typography sx={{fontSize: '12px', color: '#757679', pt: 2}}>
        Allows the voicebot to bypass its response and wait for the next user input if it detects that the user has not
        fully completed their statement. By setting a custom description, you can guide the voicebot to make more
        effective decisions regarding when to wait for additional user input, ensuring it remains silent until all
        information has been provided.
      </Typography>
      <Box pt={2} maxWidth="75%">
        <WaitDescriptionField form={form} path={`${path}.parameters.description`} />
      </Box>
    </Box>
  );
}
