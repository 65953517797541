import {VoiceBotsClient} from '@wildix/wim-voicebots-client';

import {ENV} from '../../env';
import {getToken} from './getWimConsoleSettings';

let client: VoiceBotsClient | undefined;

export default function getWimVoiceBotsClient(): VoiceBotsClient {
  if (!client) {
    client = new VoiceBotsClient({
      env: ENV,
      token: {
        token: getToken,
      },
    });
  }

  return client;
}
