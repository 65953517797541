import {WIM_API} from '../../env';
import ApiError from '../errors/ApiError';
import {IntegrationsResponse} from '../types';
import {getToken} from './getWimConsoleSettings';

export default async function fetchIntegrations(): Promise<IntegrationsResponse> {
  const token = await getToken();
  const options = {
    headers: {Authorization: `Bearer ${token}`},
  };

  return fetch(`${WIM_API}/console/integrations`, options)
    .then((res) => {
      if (!res.ok) {
        throw new ApiError(res);
      } else {
        return res.json();
      }
    })
    .then(({success, result, message}) => {
      if (!success) {
        throw new Error(message);
      }

      return result;
    });
}
