import React, {MouseEventHandler, ReactNode} from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Grid} from '@mui/material';

interface InstallBtnProps {
  installSettings: ReactNode;
  onInstallClick: MouseEventHandler;
  isInstallLoading?: boolean;
}

export default function InstallBtn({installSettings, onInstallClick, isInstallLoading}: InstallBtnProps) {
  return (
    <Grid sx={{marginLeft: '8px'}}>
      {installSettings ? <Box sx={{marginTop: 3}}>{installSettings}</Box> : ''}
      <Box sx={{marginTop: 3}}>
        <LoadingButton variant="contained" onClick={onInstallClick} size="medium" loading={isInstallLoading}>
          Install
        </LoadingButton>
      </Box>
    </Grid>
  );
}
