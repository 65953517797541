import React from 'react';

import {Stack} from '@mui/material';
import Box from '@mui/material/Box';

import {TraceSegmentResult} from '@wildix/wim-voicebots-client';

import formatToolCallDescription from '../../helpers/formatToolCallDescription';
import ContentTitle from './content/ContentTitle';
import ContentValue from './content/ContentValue';
import ContextJsonOrTextValue from './content/ContextJsonOrTextValue';
import ToolContent from './content/ToolContent';

interface TraceSegmentResultContentProps {
  result: TraceSegmentResult;
}

export default function TraceSegmentResultContent(props: TraceSegmentResultContentProps) {
  const {result} = props;

  if (result.error) {
    return (
      <Box>
        <ContentTitle>Error</ContentTitle>
        <ContentValue>{result.error.message}</ContentValue>
      </Box>
    );
  }

  return (
    <Box>
      <ContentTitle>Result</ContentTitle>
      {result.text && <ContextJsonOrTextValue text={result.text} />}
      {result.tools &&
        result.tools.map((tool, index) => {
          const description = formatToolCallDescription(tool);

          return (
            <Box key={`tool_${index}`} sx={{paddingTop: '8px'}}>
              <Stack alignItems="flex-start">
                <Box
                  sx={{
                    fontSize: '13px',
                    fontWeight: '500',
                    color: 'rgba(19, 103, 168, 1)',
                  }}>
                  {description.title}
                </Box>
              </Stack>
              <Box sx={{paddingTop: '6px', fontSize: '13px'}}>
                <ToolContent description={description} />
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}
