import React from 'react';

import Box from '@mui/material/Box';

import {TraceGenericInputBlock} from '@wildix/wim-voicebots-client';

import ContentTitle from './content/ContentTitle';
import ContentValue from './content/ContentValue';
import ContextJsonOrTextValue from './content/ContextJsonOrTextValue';

interface TraceGenericSegmentContentProps {
  blocks?: TraceGenericInputBlock[];
}

export default function TraceGenericSegmentContent(props: TraceGenericSegmentContentProps) {
  const {blocks} = props;

  return (
    <Box>
      {blocks &&
        blocks.map(({title, content}, index) => {
          let value: React.ReactNode;

          if (content.text) {
            value = <ContentValue>{content.text}</ContentValue>;
          } else if (content.json) {
            value = <ContextJsonOrTextValue text={JSON.stringify(content.json)} />;
          }

          return (
            <Box key={`block_${index}`}>
              <ContentTitle>{title}</ContentTitle>
              {value}
            </Box>
          );
        })}
    </Box>
  );
}
