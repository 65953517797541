import React from 'react';

import {SvgIcon} from '@mui/material';
import {SvgIconProps} from '@mui/material/SvgIcon/SvgIcon';

export default function CloseIcon18(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M4.65235 12.1914C4.35352 12.4902 4.3418 13.0293 4.65821 13.3457C4.97461 13.6621 5.51368 13.6504 5.81251 13.3574L9.00001 10.1699L12.1816 13.3516C12.4922 13.6621 13.0195 13.6621 13.3359 13.3398C13.6465 13.0234 13.6523 12.4961 13.3418 12.1914L10.1602 9.00977L13.3418 5.82227C13.6523 5.51758 13.6523 4.98438 13.3359 4.67383C13.0195 4.35742 12.4922 4.35742 12.1816 4.66211L9.00001 7.84375L5.81251 4.65625C5.51368 4.36328 4.97461 4.35156 4.65821 4.66797C4.34766 4.98438 4.35352 5.52344 4.65235 5.82227L7.83985 9.00977L4.65235 12.1914Z"
        fill="inherit"
      />
    </SvgIcon>
  );
}
