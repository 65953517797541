import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import IntegrationScreen from '../../../base/components/IntegrationScreen';
import LoadingBox from '../../../base/components/LoadingBox';
import findIntegrationByService from '../../../base/helpers/findIntegrationByService';
import GoogleCalendarIcon from '../../../base/icons/GoogleCalendarIcon';
import {IntegrationService} from '../../../base/types';
import {GOOGLE_CALENDAR_DESCRIPTION, GOOGLE_CALENDAR_TITLE} from '../../../texts';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import {GoogleIntegrationPath} from '../types';
import GoogleIntegrationSettings from './GoogleIntegrationSettings';

export default function GoogleIntegrationScreen() {
  const navigate = useNavigate();
  const {isLoading, isError, error, data: {installed = []} = {}} = useConsoleIntegrations();
  const integration = findIntegrationByService(installed, IntegrationService.GOOGLE_CALENDAR);

  const onInstallClick = useCallback(() => {
    navigate(GoogleIntegrationPath.install);
  }, [navigate]);

  if (isLoading) {
    return <LoadingBox />;
  }

  if (isError) {
    return <ApiErrorBox error={error} />;
  }

  return (
    <IntegrationScreen
      title={GOOGLE_CALENDAR_TITLE}
      description={GOOGLE_CALENDAR_DESCRIPTION}
      icon={<GoogleCalendarIcon width={96} height={96} />}
      integration={integration}
      integrationSettings={integration && <GoogleIntegrationSettings integration={integration} />}
      onInstallClick={onInstallClick}>
      {/*<Box mt={4}>*/}
      {/*  <Typography color="text.primary" variant="h6">Settings</Typography>*/}
      {/*  <Box mt={1} sx={{padding: 1, pl: 2, pr: 2, border: '1px solid #ccc', borderRadius: '6px'}}>*/}
      {/*    <Typography color="text.primary" variant="caption" >Integration does not have any settings to configure</Typography>*/}
      {/*  </Box>*/}
      {/*</Box>*/}
    </IntegrationScreen>
  );
}
