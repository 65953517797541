import React from 'react';

import {SvgIcon} from '@mui/material';
import {styled} from '@mui/system';

const SvgIconStyled = styled(SvgIcon)({
  fill: 'var(--blueAccent168__LM_blueAccent255__DM)',
});

export default function ArrowRightIconWeb20() {
  return (
    <SvgIconStyled viewBox="0 0 20 20" style={{width: 20, height: 20}}>
      <path
        d="M13.7515 10.6646C13.7515 10.8296 13.688 10.9819 13.561 11.1089L8.82568 15.7363C8.71143 15.8506 8.56543 15.9141 8.40039 15.9141C8.06396 15.9141 7.80371 15.6538 7.80371 15.311C7.80371 15.146 7.86719 15 7.9751 14.8857L12.2979 10.6646L7.9751 6.43701C7.86719 6.3291 7.80371 6.17676 7.80371 6.01172C7.80371 5.67529 8.06396 5.41504 8.40039 5.41504C8.56543 5.41504 8.71143 5.47852 8.82568 5.59277L13.561 10.2202C13.688 10.3472 13.7515 10.4932 13.7515 10.6646Z"
        fill="inherit"
      />
    </SvgIconStyled>
  );
}
