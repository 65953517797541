import React from 'react';

import {Box} from '@mui/material';

import {TraceActivity} from '@wildix/wim-voicebots-client';

import ContentTitle from './content/ContentTitle';
import ContentValue from './content/ContentValue';

interface TraceActivityContentProps {
  activity: TraceActivity;
}

export default function TraceActivityInputContent(props: TraceActivityContentProps) {
  const {activity} = props;
  const {trigger} = activity;
  const components: React.ReactNode[] = [];

  if (trigger.replyEvent) {
    components.push(<ContentTitle key="reply_title">Input</ContentTitle>);
    components.push(<ContentValue key="reply_value">{trigger.replyEvent.event.text}</ContentValue>);
  } else if (trigger.interruptionEvent) {
    components.push(<ContentTitle key="interruption_played_title">Played Text</ContentTitle>);
    components.push(
      <ContentValue key="interruption_played_value">{trigger.interruptionEvent.event.playedText}</ContentValue>,
    );
    components.push(<ContentTitle key="interruption_original_title">Original Text</ContentTitle>);
    components.push(
      <ContentValue key="interruption_original_value">{trigger.interruptionEvent.event.originalText}</ContentValue>,
    );
  } else if (trigger.playbackEvent) {
    components.push(<ContentTitle key="playback_title">Text</ContentTitle>);
    components.push(<ContentValue key="playback_value">{trigger.playbackEvent.event.text}</ContentValue>);
  } else if (trigger.transcriptionEvent) {
    components.push(<ContentTitle key="transcription_title">Transcription</ContentTitle>);
    components.push(<ContentValue key="transcription_value">{trigger.transcriptionEvent.event.text}</ContentValue>);
  } else if (trigger.sayCommand) {
    components.push(<ContentTitle key="input_title">Input</ContentTitle>);
    components.push(<ContentValue key="input_value">{trigger.sayCommand.command.text}</ContentValue>);
    components.push(<ContentTitle key="interruptible_title">Interruptible</ContentTitle>);
    components.push(
      <ContentValue key="interruptible_value">{trigger.sayCommand.command.interruptible ? 'Yes' : 'No'}</ContentValue>,
    );
  } else if (trigger.transferCommand) {
    components.push(<ContentTitle key="context_title">Context</ContentTitle>);
    components.push(<ContentValue key="context_value">{trigger.transferCommand.command.context}</ContentValue>);
    components.push(<ContentTitle key="extension_title">Extension</ContentTitle>);
    components.push(<ContentValue key="extension_value">{trigger.transferCommand.command.extension}</ContentValue>);
  }

  if (components.length === 0) {
    return null;
  }

  return <Box>{components}</Box>;
}
