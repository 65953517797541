import {useEffect, useReducer} from 'react';

import {
  PubSubBroadcastWebsocketEvent,
  Trace,
  TraceActivity,
  TraceSegment,
  TraceSession,
} from '@wildix/wim-voicebots-client';

import {find, values} from 'lodash';

import {getToken} from '../../../base/helpers/getWimConsoleSettings';
import {ENV} from '../../../env';

const initialState = {traces: {}};

interface State {
  traces: Record<string, Trace>;
}
type ActionType = 'session' | 'activity' | 'segment';
interface Action {
  type: ActionType;
  sessionId?: string;
  session?: TraceSession;
  activity?: TraceActivity;
  segment?: TraceSegment;
}

function tracesReducer(state: State, action: Action) {
  switch (action.type) {
    case 'session': {
      const {session} = action;

      if (!session) {
        return state;
      }

      const {sessionId} = session;

      return {
        ...state,
        traces: {
          ...state.traces,
          [sessionId]: {
            session,
            activities: state.traces[sessionId]?.activities || [],
            segments: state.traces[sessionId]?.segments || [],
          },
        },
      };
    }

    case 'activity': {
      const {activity, sessionId} = action;

      if (!activity || !sessionId) {
        return state;
      }

      if (!state.traces[sessionId]) {
        return state;
      }

      const prevActivities = state.traces[sessionId]?.activities || [];
      const prevActivity = find(prevActivities, {id: activity.id});

      if (prevActivity && prevActivity.version > activity.version) {
        // Ignore events that have lower version.
        return state;
      }

      const newActivities = prevActivities
        .filter(({id}) => id !== activity.id)
        .concat([activity])
        .sort((a, b) => b.start - a.start); // descending order

      return {
        ...state,
        traces: {
          ...state.traces,
          [sessionId]: {
            ...state.traces[sessionId],
            activities: newActivities,
            segments: state.traces[sessionId]?.segments || [],
          },
        },
      };
    }

    case 'segment': {
      const {segment, sessionId} = action;

      if (!segment || !sessionId) {
        return state;
      }

      if (!state.traces[sessionId]) {
        return state;
      }

      const prevSegments = state.traces[sessionId]?.segments || [];
      const prevSegment = find(prevSegments, {id: segment.id});

      if (prevSegment && prevSegment.version > segment.version) {
        // Ignore events that have lower version.
        return state;
      }

      const newSegments = prevSegments
        .filter(({id}) => id !== segment.id)
        .concat([segment])
        .sort((a, b) => b.start - a.start); // descending order

      return {
        ...state,
        traces: {
          ...state.traces,
          [sessionId]: {
            ...state.traces[sessionId],
            segments: newSegments,
            activities: state.traces[sessionId]?.activities || [],
          },
        },
      };
    }

    default:
      return state;
  }
}

export default function useOngoingTracesSubscription(): Trace[] {
  const [state, dispatch] = useReducer(tracesReducer, initialState);

  useEffect(() => {
    const connection: {ws: WebSocket | undefined} = {ws: undefined};
    const timer = setTimeout(async () => {
      const token = await getToken();
      let domain = 'wim-voicebots-pubsub-ws.wildix.com';

      if (ENV === 'stage' || ENV === 'stable') {
        domain = `wim-voicebots-pubsub-ws-${ENV}.wildix.com`;
      }

      connection.ws = new WebSocket(`wss://${domain}/?authorization=${encodeURIComponent(token!)}`);

      connection.ws.onopen = () => {
        if (connection.ws) {
          connection.ws.send(
            JSON.stringify({
              action: 'subscribe',
              topic: 'traces',
            }),
          );
        }
      };

      connection.ws.onclose = () => {
        // Ignore
      };

      connection.ws.onmessage = (event) => {
        const message = JSON.parse(event.data) as PubSubBroadcastWebsocketEvent;
        const {topic} = message;

        if (topic === 'traces' && message.items) {
          message.items.forEach((item) => {
            if (item.session) {
              dispatch({
                type: 'session',
                session: item.session,
              });
            } else if (item.activity && item.sessionId) {
              dispatch({
                type: 'activity',
                sessionId: item.sessionId,
                activity: item.activity,
              });
            } else if (item.segment && item.sessionId) {
              dispatch({
                type: 'segment',
                sessionId: item.sessionId,
                segment: item.segment,
              });
            }
          });
        }
      };
    }, 500);

    return () => {
      clearTimeout(timer);

      if (connection.ws) {
        connection.ws.close();
      }
    };
  }, [dispatch]);

  return values(state.traces).sort((traceA, traceB) => traceB.session.start - traceA.session.start); // descending order
}
