import React, {ReactNode} from 'react';

import Box from '@mui/material/Box';
import {styled} from '@mui/system';

import {TraceSegmentCompletionInput} from '@wildix/wim-voicebots-client';

import {flatMap} from 'lodash';

import formatToolCallDescription from '../../helpers/formatToolCallDescription';
import formatToolCallResult from '../../helpers/formatToolCallResult';
import ContentTitle from './content/ContentTitle';
import ContentValue from './content/ContentValue';
import ToolContent from './content/ToolContent';

interface TraceCompletionSegmentContentProps {
  completion: TraceSegmentCompletionInput;
}

const MessagesBox = styled(Box)({
  marginTop: '8px',
  padding: '12px',
  borderRadius: '6px',
  backgroundColor: '#FAFAFA',
});

const MessageRoleBox = styled(Box)({
  fontWeight: '500',
  fontSize: '13px',
  color: 'rgba(19, 103, 168, 1)',
  paddingTop: '12px',
  '&:first-of-type': {
    paddingTop: '0',
  },
});

const MessageTextBox = styled(Box)({
  fontSize: '13px',
  color: 'black',
  paddingTop: '4px',
  margin: '0',
  fontFamily: 'monospace',
  overflow: 'auto',
  overflowWrap: 'break-word',
  whiteSpace: 'pre-wrap',
});

const MessageToolsBox = styled(Box)({
  fontSize: '13px',
  color: 'black',
  paddingTop: '4px',
  margin: '0',
});

export default function TraceCompletionSegmentContent(props: TraceCompletionSegmentContentProps) {
  const {completion} = props;
  const {messages, instructions} = completion;

  const conversation: ReactNode[] = flatMap(
    messages.map(({bot, user}, index) => {
      const title = user ? 'User' : 'Bot';
      let content: ReactNode | undefined;

      if (user) {
        content = (
          <MessageTextBox component="pre" key={`content_${index}`}>
            {user.text}
          </MessageTextBox>
        );
      } else if (bot?.text) {
        content = (
          <MessageTextBox component="pre" key={`content_${index}`}>
            {bot.text}
          </MessageTextBox>
        );
      } else if (bot?.tools) {
        return bot.tools.map((tool, toolIndex) => {
          const description = formatToolCallDescription(tool.call);
          const result = formatToolCallResult(tool.result);

          return [
            <MessageRoleBox key={`title_${index}`}>
              {toolIndex > 0 ? `${index + 1}.${toolIndex + 1}` : index + 1}. {description.title}
            </MessageRoleBox>,
            <MessageToolsBox key={`content_${index}`}>
              <ToolContent description={description} result={result} />
            </MessageToolsBox>,
          ];
        });
      }

      if (!content) {
        return [];
      }

      return [
        <MessageRoleBox key={`title_${index}`}>
          {index + 1}. {title}
        </MessageRoleBox>,
        content,
      ];
    }),
  );

  return (
    <Box>
      <ContentTitle>Instructions</ContentTitle>
      <ContentValue>{instructions}</ContentValue>
      <ContentTitle>Conversation</ContentTitle>
      <MessagesBox>{conversation}</MessagesBox>
    </Box>
  );
}
