import {UsersClient} from '@wildix/xbees-users-client';

import {ENV} from '../../env';
import {getToken} from './getWimConsoleSettings';

let client: UsersClient | undefined;

export default function getXbsUsersClient(): UsersClient {
  if (!client) {
    client = new UsersClient({
      env: ENV,
      token: {
        token: getToken,
      },
    });
  }

  return client;
}
