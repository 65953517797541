import {TraceActivity} from '@wildix/wim-voicebots-client';

interface Description {
  title: string;
  color: string;
  content?: string;
}

export default function formatActivityDescription(activity: TraceActivity): Description {
  const {trigger} = activity;

  if (trigger.connectEvent) {
    return {title: 'Session Started', color: 'rgba(0,128,0,.9)'};
  } else if (trigger.disconnectEvent) {
    return {title: 'Session Ended', color: '#C75858'};
  } else if (trigger.replyEvent) {
    return {title: 'Reply', content: trigger.replyEvent.event.text, color: 'rgba(28,96,217,.9)'};
  } else if (trigger.completeEvent) {
    return {title: 'Complete', color: '#51B07F'};
  } else if (trigger.interruptionEvent) {
    return {title: 'Interruption', color: '#C75858'};
  } else if (trigger.playbackEvent) {
    return {
      title: trigger.playbackEvent.event.complete ? 'Playback Ended' : 'Playback Started',
      content: trigger.playbackEvent.event.text,
      color: '#853785',
    };
  } else if (trigger.transcriptionEvent) {
    return {title: 'Transcription', content: trigger.transcriptionEvent.event.text, color: '#B06A19'};
  } else if (trigger.sayCommand) {
    return {title: 'Say', content: trigger.sayCommand.command.text, color: '#3F3FC4'};
  } else if (trigger.hangupCommand) {
    return {title: 'Hangup', color: 'rgba(139,0,0,.9)'};
  } else if (trigger.transferCommand) {
    return {title: 'Transfer', color: 'rgba(255,136,0,.8)'};
  } else if (trigger.silenceTimeoutTask) {
    return {title: 'Silence Detection', color: 'rgba(165,42,42,.9)'};
  } else if (trigger.durationTimeoutTask) {
    return {title: 'Max Duration Detection', color: 'rgba(64,64,64,.9)'};
  }

  return {title: 'Unknown', color: 'rgba(0,0,0,.9)'};
}
