import React from 'react';
import {Controller, FieldErrors, UseFormReturn} from 'react-hook-form';

import Check from '@mui/icons-material/Check';
import {InputAdornment, styled, TextField} from '@mui/material';
import Button from '@mui/material/Button';

import {VoiceBotEndpoint} from '@wildix/wim-voicebots-client';

import {get} from 'lodash';
import {useSnackbar} from 'material-ui-snackbar-provider';

import {BotFormData} from '../../types';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface BotDialogflowCxPrivateKeyFieldProps {
  form: UseFormReturn<BotFormData>;
}

function getCredentialsProjectId(credentials: unknown) {
  return get(credentials, 'project_id', 'unknown');
}

export default function BotDialogflowCxCredentialsField(props: BotDialogflowCxPrivateKeyFieldProps) {
  const snackbar = useSnackbar();
  const {form} = props;
  const {control, formState, trigger, setValue} = form;
  const {errors, isSubmitting} = formState;
  // @ts-ignore
  const error = (errors?.endpoint as FieldErrors<VoiceBotEndpoint.DialogflowCxMember>)?.dialogflowCx?.credentials;

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = function () {
        try {
          const content = reader.result as string;
          const json = JSON.parse(content);

          setValue('endpoint.dialogflowCx.credentials', json);
        } catch (errorParse) {
          snackbar.showMessage((errorParse as Error).toString());
        }
      };

      reader.onerror = function (readerError) {
        snackbar.showMessage(readerError.toString());
      };
    }
  };

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          required
          fullWidth
          label="Private Key"
          placeholder="Service Account Private Key"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <Button component="label" role={undefined} variant="text" size="small" tabIndex={-1} sx={{mr: -1}}>
                  Upload
                  <VisuallyHiddenInput type="file" accept=".json" onChange={onFileChange} />
                </Button>
              </InputAdornment>
            ),
            startAdornment: value ? (
              <InputAdornment position="start">
                <Check sx={{width: '16px', height: '16px', color: 'green'}} />
              </InputAdornment>
            ) : undefined,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          disabled={isSubmitting}
          value={value ? getCredentialsProjectId(value) : ''}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name="endpoint.dialogflowCx.credentials"
      rules={{required: true}}
    />
  );
}
