import React, {ClipboardEvent, KeyboardEvent, useEffect, useRef, useState} from 'react';

import {Button, Chip, TextField} from '@mui/material';

import {difference} from 'lodash';

import styled from '@emotion/styled';

const ChipContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  '& > div': {
    margin: '4px',
  },
});

const InputContainer = styled('div')({
  paddingTop: '8px',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
});

interface ChipListProps {
  items: string[];
  onChange?: (value: string[]) => void;
  label: string;
  id: string;
  validation?: (value: string, index: number, length: number) => string | undefined;
}

const separateKeys = [',', 'Enter'];

const ChipList: React.FC<ChipListProps> = ({items, onChange, label, id, validation}) => {
  const [value, setValue] = useState<string[]>();
  const [errorMessage, setError] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);
  const currentItems = value || items || [];

  const handleDelete = (item: string) => {
    setValue((prev) => currentItems.filter((i) => i !== item));
  };

  const parseList = (value: string): string[] =>
    value
      .split(/[,\n\t\s]+/)
      .map((i) => i.trim())
      .filter(Boolean);

  const resetInput = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }, 0);
  };

  const handleChange = (input: string | undefined) => {
    if (!input) {
      return;
    }

    setError('');
    const added = parseList(input).filter((item) => !currentItems.includes(item));
    const newValue = [...currentItems, ...added];

    if (validation) {
      const error = added.map((item, index) => validation(item, index, added.length)).find(Boolean);

      if (error) {
        setError(error);

        return;
      }
    }

    setValue(newValue);
    resetInput();
  };

  useEffect(() => {
    if (onChange && value) {
      onChange(value!);
    }
  }, [value]);

  useEffect(() => {
    if (difference(items, value || [])) {
      setValue(items);
    }
  }, [items]);

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    handleChange(event.clipboardData.getData('text'));
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (separateKeys.includes(event.key)) {
      handleChange(inputRef.current?.value || '');
    }
  };

  return (
    <div>
      <ChipContainer>
        {currentItems.map((item, index) => (
          <Chip key={`chip-${item}`} label={item} variant="outlined" onDelete={() => handleDelete(item)} />
        ))}
      </ChipContainer>
      {currentItems.length ? <Button onClick={() => setValue([])}>Remove all</Button> : null}
      <InputContainer>
        <TextField
          error={Boolean(errorMessage)}
          id="filled-error"
          inputRef={inputRef}
          label={label}
          helperText={errorMessage}
          onKeyUp={handleKeyUp}
          onPaste={handlePaste}
          fullWidth
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button onClick={() => handleChange(inputRef.current?.value)}>Add</Button>
      </InputContainer>
    </div>
  );
};

export default ChipList;
