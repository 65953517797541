import {TraceSegment} from '@wildix/wim-voicebots-client';

interface Description {
  title: string;
}

export default function formatSegmentDescription(segment: TraceSegment): Description {
  if (segment.input.completion) {
    return {title: 'Completion'};
  } else if (segment.input.oauth) {
    return {title: 'OAuth'};
  } else if (segment.input.tool?.function) {
    return {title: 'Third-party Function'};
  } else if (segment.input.tool?.hangup) {
    return {title: 'Hangup'};
  } else if (segment.input.tool?.wait) {
    return {title: 'Wait'};
  } else if (segment.input.tool?.delegation) {
    return {title: 'Delegation'};
  } else if (segment.input.tool?.transfer) {
    return {title: 'Transfer'};
  } else if (segment.input.generic) {
    return {title: segment.input.generic.title};
  }

  return {title: 'Unknown'};
}
