import React from 'react';

import {IconButton, Stack} from '@mui/material';
import Box from '@mui/material/Box';

import {TraceSegment} from '@wildix/wim-voicebots-client';

import BackIconWeb24 from '../../../../base/icons/BackIconWeb24';
import formatSegmentDescription from '../../helpers/formatSegmentDescription';
import TraceCompletionSegmentContent from './TraceCompletionSegmentContent';
import TraceGenericSegmentContent from './TraceGenericSegmentContent';
import TraceOauthSegmentContent from './TraceOauthSegmentContent';
import TraceSegmentResultContent from './TraceSegmentResultContent';
import TraceToolSegmentContent from './TraceToolSegmentContent';

interface TraceSegmentContentProps {
  segment: TraceSegment;
  onClose: () => void;
}

export default function TraceSegmentContent(props: TraceSegmentContentProps) {
  const {segment, onClose} = props;
  const description = formatSegmentDescription(segment);

  return (
    <Box sx={{flex: '1', padding: '8px 18px 42px 18px', overflow: 'auto'}}>
      <Stack flexDirection="row" alignItems="center">
        <IconButton sx={{marginLeft: '-12px'}} onClick={onClose}>
          <BackIconWeb24 />
        </IconButton>
        <Box sx={{flex: '1', fontSize: '18px', fontWeight: '500'}}>{description.title}</Box>
      </Stack>
      {segment.input.tool && <TraceToolSegmentContent tool={segment.input.tool} />}
      {segment.input.completion && <TraceCompletionSegmentContent completion={segment.input.completion} />}
      {segment.input.oauth && <TraceOauthSegmentContent oauth={segment.input.oauth} />}
      {segment.input.generic && <TraceGenericSegmentContent blocks={segment.input.generic.blocks} />}
      {segment.result && <TraceSegmentResultContent result={segment.result} />}
    </Box>
  );
}
