import React from 'react';

import Box from '@mui/material/Box';
import {styled} from '@mui/system';

import {isString} from 'lodash';

import {ToolCallDescription} from '../../../helpers/formatToolCallDescription';

import ReactJson from '@microlink/react-json-view';

const ToolSectionBox = styled(Box)({
  border: '1px solid rgba(236, 241, 244, 1)',
  borderRadius: '8px',
  overflow: 'hidden',
  marginTop: '8px',
  '&:first-child': {
    marginTop: '0',
  },
});

const ToolTitleBox = styled(Box)({
  backgroundColor: 'rgba(236, 241, 244, 1)',
  padding: '4px 8px',
});

const ToolContentBox = styled(Box)({
  padding: '4px',
  borderRadius: '6px',
  fontFamily: 'monospace',
  fontSize: '13px',
  backgroundColor: '#FAFAFA',
  overflow: 'auto',
});

interface ToolContentProps {
  description: ToolCallDescription;
  result?: string | object;
}

export default function ToolContent(props: ToolContentProps) {
  const {description, result} = props;

  return (
    <Box>
      {description.args && (
        <ToolSectionBox>
          <ToolTitleBox>Arguments</ToolTitleBox>
          <ToolContentBox>
            {!isString(description.args) && (
              <ReactJson
                src={description.args}
                name={null}
                iconStyle="circle"
                displayObjectSize={false}
                displayDataTypes={false}
              />
            )}
            {isString(description.args) && <Box>{description.args}</Box>}
          </ToolContentBox>
        </ToolSectionBox>
      )}
      {result && (
        <ToolSectionBox>
          <ToolTitleBox>Result</ToolTitleBox>
          <ToolContentBox>
            {!isString(result) && (
              <ReactJson
                src={result}
                name={null}
                iconStyle="circle"
                displayObjectSize={false}
                displayDataTypes={false}
              />
            )}
            {isString(result) && <Box>{result}</Box>}
          </ToolContentBox>
        </ToolSectionBox>
      )}
    </Box>
  );
}
