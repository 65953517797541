import {useState} from 'react';
import SwipeableViews from 'react-swipeable-views';

import Box from '@mui/material/Box';

import {Trace} from '@wildix/wim-voicebots-client';

import {find} from 'lodash';

import TraceActivityContent from './TraceActivityContent';
import TraceSegmentContent from './TraceSegmentContent';

interface TraceContentProps {
  trace?: Trace;
  activityId?: string;
}

export default function TraceContent(props: TraceContentProps) {
  const {trace, activityId} = props;
  const [selectedSegmentId, setSelectedSegmentId] = useState<string | undefined>();
  let segment;
  let activity;
  let activitySegments;

  if (trace && selectedSegmentId) {
    segment = find(trace.segments, {id: selectedSegmentId, activityId});
  }

  if (trace && activityId) {
    activity = find(trace.activities, {id: activityId});
    activitySegments = trace.segments.filter((segment) => segment.activityId === activityId);
  }

  const onSegmentClick = (segmentId: string) => {
    setSelectedSegmentId(segmentId);
  };

  const onSegmentClose = () => {
    setSelectedSegmentId(undefined);
  };

  if (!segment && !activity) {
    return null;
  }

  return (
    <Box sx={{overflow: 'auto', flex: '1'}}>
      <SwipeableViews disabled index={segment ? 1 : 0}>
        {activity && activitySegments ? (
          <TraceActivityContent activity={activity} segments={activitySegments} onSegmentClick={onSegmentClick} />
        ) : (
          <Box />
        )}
        {segment ? <TraceSegmentContent segment={segment} onClose={onSegmentClose} /> : <Box />}
      </SwipeableViews>
    </Box>
  );

  // return <TraceActivitySegment />;
}
