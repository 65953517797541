import {TraceToolCall} from '@wildix/wim-voicebots-client/dist-types/models/models_0';

export interface ToolCallDescription {
  title: string;
  args?: string | object;
}

export default function formatToolCallDescription(call?: TraceToolCall): ToolCallDescription {
  if (call?.wait) {
    return {title: 'Wait'};
  } else if (call?.function) {
    let {args} = call.function;
    const {name} = call.function;

    if (args && args !== '{}') {
      try {
        args = JSON.parse(args);
      } catch (jsonParseError) {
        // ignore
      }
    } else {
      args = undefined;
    }

    return {title: `Third-party Function: ${name}`, args};
  } else if (call?.hangup) {
    return {title: 'Hangup'};
  } else if (call?.transfer) {
    return {title: 'Transfer', args: {context: call.transfer.context, extension: call.transfer.extension}};
  } else if (call?.delegation) {
    return {title: 'Delegate', args: {id: call.delegation.id}};
  }

  return {title: 'Unknown'};
}
