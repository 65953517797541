import {format} from 'date-fns/format';
import {isSameYear} from 'date-fns/isSameYear';
import {isToday} from 'date-fns/isToday';
import {isYesterday} from 'date-fns/isYesterday';

export default function formatDateTime(date: Date) {
  if (isToday(date)) {
    return `Today, ${format(date, 'HH:mm')}`; //  : ' p'
  } else if (isYesterday(date)) {
    return `Yesterday ${format(date, 'HH:mm')}`;
  } else if (!isSameYear(date, new Date())) {
    return format(date, 'd MMM, yyyy HH:mm'); //  : 'd MMM, yyyy p'
  } else {
    return format(date, 'd MMM, HH:mm'); //  : 'd MMM p'
  }
}
