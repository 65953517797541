import React from 'react';
import {ItemProps, TableComponents, TableVirtuoso} from 'react-virtuoso';

import {CircularProgress} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {styled} from '@mui/system';

import {TraceSession, TraceSessionStatus} from '@wildix/wim-voicebots-client';

import formatDateTime from '../../helpers/formatDateTime';
import formatDurationFromMillis from '../../helpers/formatDurationFromMillis';

interface TableContext {
  openDetails: (item: TraceSession) => void;
}

const TracesTableCell = styled(TableCell)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '13px',
});

const TracesTableRow = styled(TableRow)({
  '&.pending': {
    backgroundColor: 'rgba(210, 225, 238, 0.35)',
  },
  '&:hover': {
    backgroundColor: '#F9F9F9',
    cursor: 'pointer',
  },
  '&.pending:hover': {
    backgroundColor: 'rgba(210, 225, 238, 0.5)',
  },
});

const TracesTableHeaderCell = styled(TableCell)({
  backgroundColor: 'white',
});

function TableRowCustom(props: ItemProps<TraceSession> & {context?: TableContext}) {
  const {context, item, ...restProps} = props;
  const isPending = item.status === TraceSessionStatus.PENDING;

  return (
    <TracesTableRow
      {...restProps}
      className={isPending ? 'pending' : undefined}
      onClick={() => context?.openDetails(item)}
    />
  );
}

const TracesTableComponents: TableComponents<TraceSession, TableContext> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        border: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '8px',
      }}
      {...props}
      ref={ref}
    />
  )),
  Table: (props) => <Table {...props} sx={{borderCollapse: 'separate', tableLayout: 'fixed'}} />,
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow: TableRowCustom,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
};

function TracesTableHeaderContent() {
  return (
    <TableRow>
      <TracesTableHeaderCell variant="head" align="left" sx={{width: '110px'}}>
        Session ID
      </TracesTableHeaderCell>
      <TracesTableHeaderCell variant="head" align="left" sx={{width: '100px'}}>
        Voice Bot
      </TracesTableHeaderCell>
      <TracesTableHeaderCell variant="head" align="left" sx={{width: '160px'}}>
        Caller
      </TracesTableHeaderCell>
      <TracesTableHeaderCell variant="head" align="left" sx={{width: '80px'}}>
        Duration
      </TracesTableHeaderCell>
      <TracesTableHeaderCell variant="head" align="left" sx={{width: '120px'}}>
        Date
      </TracesTableHeaderCell>
      <TracesTableHeaderCell variant="head" align="left" sx={{width: '80px'}}>
        Language
      </TracesTableHeaderCell>
    </TableRow>
  );
}

function TracesTableRowContent(_index: number, session: TraceSession) {
  const {sessionId, botName, callerNumber, callerName, duration, start, language} = session;

  return (
    <>
      <TracesTableCell>{sessionId}</TracesTableCell>
      <TracesTableCell>{botName}</TracesTableCell>
      <TracesTableCell>
        {callerName} ({callerNumber})
      </TracesTableCell>
      <TracesTableCell>
        {duration ? formatDurationFromMillis(duration) : <CircularProgress size={12} />}
      </TracesTableCell>
      <TracesTableCell>{start ? formatDateTime(new Date(start)) : '-'}</TracesTableCell>
      <TracesTableCell>{language ? language : '-'}</TracesTableCell>
    </>
  );
}

export interface TracesTableContext {
  openDetails: (item: TraceSession) => void;
}

export interface TracesTableProps {
  traces: TraceSession[];
  isLoading: boolean;
  context: TracesTableContext;
}

export default function TracesTable(props: TracesTableProps) {
  const {traces, context, isLoading} = props;

  return (
    <TableVirtuoso
      data={traces}
      context={context}
      components={TracesTableComponents}
      fixedHeaderContent={TracesTableHeaderContent}
      itemContent={TracesTableRowContent}
    />
  );
}
