import {Box, BoxProps, styled} from '@mui/material';

const ContentValueBox = styled(Box)({
  marginTop: '8px',
  padding: '12px',
  borderRadius: '6px',
  backgroundColor: '#FAFAFA',
  fontFamily: 'monospace',
  fontSize: '13px',
  overflow: 'auto',
  overflowWrap: 'break-word',
  whiteSpace: 'pre-wrap',
});

export default function ContentValue(props: BoxProps) {
  return <ContentValueBox component="pre" {...props} />;
}
