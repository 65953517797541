import React from 'react';
import {Link} from 'react-router-dom';

import {Box, Breadcrumbs, Grid, Typography} from '@mui/material';

export default function IntegrationInstallationScreen(props: any) {
  const {icon, title, link, children} = props;

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={link}>
          {title}
        </Typography>
        <Typography color="text.primary">Install</Typography>
      </Breadcrumbs>
      <Grid container columnSpacing={6} rowSpacing={2} sx={{marginTop: 2}}>
        <Grid item>{icon}</Grid>
        <Grid item xs={10}>
          <Typography color="text.primary" variant="h5">
            {title}
          </Typography>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}
