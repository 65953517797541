import React, {MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';

import {Breadcrumbs, Grid, Typography} from '@mui/material';

import useConsoleIntegrationDeleteMutation from '../../integrations/hooks/useConsoleIntegrationDeleteMutation';
import getIntegrationStatus from '../helpers/getIntegrationStatus';
import {Integration, IntegrationStatusType} from '../types';
import ErrorDialog, {ErrorDialogApi} from './error/ErrorDialog';
import IntegrationDeleteDialog from './IntegrationDeleteDialog';
import IntegrationLogContainer from './integrationLog/IntegrationLogContainer';
import IntegrationScreenButton from './IntegrationScreenButton';
import IntegrationScreenStatus from './IntegrationScreenStatus';

interface IntegrationScreenProps {
  icon: ReactNode;
  title: string;
  description: string;
  integration: Integration | null;
  isInstallLoading?: boolean;
  onInstallClick?: MouseEventHandler<HTMLButtonElement>;
  error?: string;
  children?: ReactNode;
  info?: ReactNode;
  installSettings?: ReactNode;
  integrationSettings?: ReactNode;

  //@todo remove later
  showErrors?: boolean;
}

function IntegrationScreen(props: IntegrationScreenProps) {
  const {
    icon,
    title,
    description,
    integration,
    isInstallLoading,
    onInstallClick,
    error,
    children,
    info,
    installSettings,
    integrationSettings,
    showErrors = false,
  } = props;
  const errorDialogRef = useRef<ErrorDialogApi>();

  const [status, setStatus] = useState<IntegrationStatusType | null>(getIntegrationStatus(integration));
  const [showLogs, setShowLogs] = useState<boolean>(false);

  useEffect(() => {
    const newStatus = getIntegrationStatus(integration);

    if (newStatus !== status) {
      setStatus(newStatus);
    }
  }, [integration]);

  const {mutate: deleteMutate, isLoading: isDeleteLoading} = useConsoleIntegrationDeleteMutation({
    onSuccess: (response: any) => {
      const {success, message} = response;

      if (!success) {
        errorDialogRef.current?.show(message);
        console.warn(`[${integration?.integrationId}] Unable to process integration delete request`, response);
      }
    },
  });

  useEffect(() => {
    error && errorDialogRef.current?.show(error);
  }, [error]);

  const removeDialogRef = useRef<any>();
  const onDeleteClick = useCallback(() => {
    removeDialogRef.current.open();
  }, []);
  const onDeleteConfirmClick = useCallback(() => {
    if (integration) {
      deleteMutate(integration.integrationId);
    }
  }, [integration, deleteMutate]);

  return (
    <Grid sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary">{title}</Typography>
      </Breadcrumbs>
      <Grid container columnSpacing={6} rowSpacing={2} sx={{marginTop: 2}}>
        <Grid item>{icon}</Grid>
        <Grid item xs={10}>
          <Typography color="text.primary" variant="h5">
            {title}
          </Typography>

          <Typography color="text.primary" sx={{paddingTop: 2}}>
            {description}
          </Typography>

          {info}
          {status && integrationSettings && <Grid>{integrationSettings}</Grid>}
          {onInstallClick ? (
            <Grid sx={{display: 'flex', marginTop: '20px', alignItems: status ? 'start' : 'end'}}>
              <IntegrationScreenStatus
                integration={integration}
                showErrors={showErrors}
                showLogs={showLogs}
                setShowLogs={setShowLogs}
              />
              <IntegrationScreenButton
                status={status}
                installSettings={installSettings}
                onInstallClick={onInstallClick}
                isInstallLoading={isInstallLoading}
                onDeleteClick={onDeleteClick}
                isDeleteLoading={isDeleteLoading}
              />
            </Grid>
          ) : null}

          {showLogs && integration ? <IntegrationLogContainer integration={integration} /> : null}

          {children}
        </Grid>
      </Grid>
      <IntegrationDeleteDialog apiRef={removeDialogRef} title={title} onDeleteClick={onDeleteConfirmClick} />
      <ErrorDialog apiRef={errorDialogRef} />
    </Grid>
  );
}

export default IntegrationScreen;
