import React, {ReactNode} from 'react';

import {Box, Typography} from '@mui/material';

interface FieldsetProps {
  title: ReactNode;
  children: ReactNode;
}

const Fieldset = ({title, children}: FieldsetProps) => (
  <Box
    component="fieldset"
    sx={{
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderWidth: '1px',
      borderRadius: '4px',
      padding: '8px',
      margin: 0,
    }}>
    <Typography
      component="legend"
      sx={{
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '12px',
        padding: '4px 6px',
      }}>
      {title}
    </Typography>
    <Box sx={{padding: '0 4px'}}>{children}</Box>
  </Box>
);

export default Fieldset;
