import React, {MouseEventHandler, ReactNode} from 'react';

import {IntegrationStatusType} from '../types';
import DeleteBtn from './integrationScreenButtons/DeleteBtn';
import InstallBtn from './integrationScreenButtons/InstallBtn';

interface IntegrationScreenButtonProps {
  status: IntegrationStatusType | null;

  onInstallClick: MouseEventHandler;
  isInstallLoading?: boolean;

  onDeleteClick: MouseEventHandler;
  isDeleteLoading: boolean;

  installSettings: ReactNode;
}

function IntegrationScreenButton(props: IntegrationScreenButtonProps) {
  const {status, onInstallClick, isInstallLoading, onDeleteClick, isDeleteLoading, installSettings} = props;

  switch (status) {
    case IntegrationStatusType.INSTALLING:
      return null;

    case IntegrationStatusType.WARNING:
    case IntegrationStatusType.ENABLED:
    case IntegrationStatusType.BROKEN:
      return <DeleteBtn onDeleteClick={onDeleteClick} isDeleteLoading={isDeleteLoading} />;

    default:
      return (
        <InstallBtn
          installSettings={installSettings}
          onInstallClick={onInstallClick}
          isInstallLoading={isInstallLoading}
        />
      );
  }
}

export default IntegrationScreenButton;
