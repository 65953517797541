import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import {VoiceBotFunctionIntegrationWebhookAuthorization} from '@wildix/wim-voicebots-client';

import {BotFormData} from '../../../types';
import VoiceBotTextFieldField from './base/VoiceBotTextField';
import Fieldset from './Fieldset';

interface FunctionIntegrationWebhookAuthorizationFieldProps {
  form: UseFormReturn<BotFormData>;
  path: string;
}

export default function FunctionIntegrationWebhookAuthorizationField(
  props: FunctionIntegrationWebhookAuthorizationFieldProps,
) {
  const {form, path} = props;
  const {formState, setValue, watch} = form;
  const {isSubmitting} = formState;
  const authorization = watch(path as any) as VoiceBotFunctionIntegrationWebhookAuthorization;

  if (!authorization) {
    return null;
  }

  const isBearerVariant = authorization.bearer !== undefined;
  const isBasicVariant = authorization.basic !== undefined;
  const isOAuthVariant = authorization.oauth !== undefined;
  const variant = isBearerVariant ? 'bearer' : isBasicVariant ? 'basic' : 'oauth';

  const onVariantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target as HTMLInputElement;

    if (value === 'bearer') {
      setValue(path as any, {
        bearer: {
          token: '',
        },
      });
    } else if (value === 'basic') {
      setValue(path as any, {
        basic: {
          username: '',
          password: '',
        },
      });
    } else if (value === 'oauth') {
      setValue(path as any, {
        oauth: {
          clientId: '',
          clientSecret: '',
          endpointUrl: '',
          scopes: '',
        },
      });
    } else {
      setValue(path as any, undefined);
    }
  };

  return (
    <Fieldset title="Authorization">
      <Box sx={{pb: 1}}>
        <Box sx={{mt: -1}}>
          <RadioGroup value={variant} onChange={onVariantChange} row name="row-radio-buttons-group">
            <FormControlLabel
              value="bearer"
              control={<Radio disabled={isSubmitting} size="small" />}
              label="Bearer"
              slotProps={{typography: {fontSize: '14px'}}}
            />
            <FormControlLabel
              value="basic"
              control={<Radio disabled={isSubmitting} size="small" />}
              label="Basic"
              slotProps={{typography: {fontSize: '14px'}}}
            />
            <FormControlLabel
              value="oauth"
              control={<Radio disabled={isSubmitting} size="small" />}
              label="OAuth"
              slotProps={{typography: {fontSize: '14px'}}}
            />
            <FormControlLabel
              value="none"
              control={<Radio disabled={isSubmitting} size="small" />}
              label="None"
              slotProps={{typography: {fontSize: '14px'}}}
            />
          </RadioGroup>
        </Box>
        {isBearerVariant && (
          <Box mt={1}>
            <VoiceBotTextFieldField
              form={form}
              path={`${path}.bearer.token`}
              label="Token"
              rules={{required: true}}
              disabled={isSubmitting}
            />
          </Box>
        )}
        {isBasicVariant && (
          <>
            <Box mt={1}>
              <VoiceBotTextFieldField
                form={form}
                path={`${path}.basic.username`}
                label="Username"
                rules={{required: true}}
                disabled={isSubmitting}
              />
            </Box>
            <Box mt={2}>
              <VoiceBotTextFieldField
                form={form}
                path={`${path}.basic.password`}
                label="Password"
                rules={{required: true}}
                disabled={isSubmitting}
              />
            </Box>
          </>
        )}
        {isOAuthVariant && (
          <>
            <Box mt={1}>
              <VoiceBotTextFieldField
                form={form}
                path={`${path}.oauth.clientId`}
                label="Client Id"
                rules={{required: true}}
                disabled={isSubmitting}
              />
            </Box>
            <Box mt={2}>
              <VoiceBotTextFieldField
                form={form}
                path={`${path}.oauth.clientSecret`}
                label="Client Secret"
                rules={{required: true}}
                disabled={isSubmitting}
              />
            </Box>
            <Box mt={2}>
              <VoiceBotTextFieldField
                form={form}
                path={`${path}.oauth.endpointUrl`}
                label="OAuth Endpoint URL"
                rules={{required: true}}
                disabled={isSubmitting}
              />
            </Box>
            <Box mt={2}>
              <VoiceBotTextFieldField
                form={form}
                path={`${path}.oauth.scope`}
                label="OAuth Scope"
                disabled={isSubmitting}
                required={false}
              />
            </Box>
            <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
              * Voice Bot will initiate the OAuth client credentials flow to exchange an access token from the 3rd party
              platform and put it in the auth header.
            </Typography>
          </>
        )}
      </Box>
    </Fieldset>
  );
}
