export default function formatDurationFromMillis(millis: number): string {
  if (millis < 1000) {
    return `${millis}ms`; // Less than a second, show in milliseconds
  } else if (millis < 60000) {
    const seconds = millis / 1000;

    return `${seconds.toFixed(1)}s`; // Less than a minute, show in seconds with 1 decimal place
  } else if (millis < 3600000) {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(1);

    return `${minutes}m ${seconds}s`; // Less than an hour, show in minutes and seconds
  } else {
    const hours = Math.floor(millis / 3600000);
    const minutes = Math.floor((millis % 3600000) / 60000);

    return `${hours}h ${minutes}m`; // More than an hour, show in hours and minutes
  }
}
