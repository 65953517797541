import React, {useId} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';

import {BotFormData} from '../../types';
import VoiceBotTextFieldField from './tools/base/VoiceBotTextField';

interface TimeoutActionFieldProps {
  form: UseFormReturn<BotFormData>;
  path: 'pipeline.maximumDurationAction' | 'pipeline.silenceTimeoutAction';
}

function valuetext(value: number) {
  return `${value} seconds`;
}

export default function TimeoutActionField(props: TimeoutActionFieldProps) {
  const {path, form} = props;
  const {setValue, watch, formState} = form;
  const {isSubmitting} = formState;
  const value = watch(path);

  const id = useId();

  const onTypeChange = (event: SelectChangeEvent) => {
    const data =
      event.target.value === 'transfer'
        ? {
            transfer: {
              context: '',
              extension: '',
            },
          }
        : {
            hangup: {},
          };

    setValue(path, data);
  };

  const pipelineType = value && value.transfer ? 'transfer' : 'hangup';

  return (
    <Box sx={{width: 360}}>
      <FormControl fullWidth>
        <InputLabel size="small" id={`${id}-select-label`}>
          Action
        </InputLabel>
        <Select
          labelId={`${id}-select-label`}
          value={pipelineType}
          disabled={isSubmitting}
          size="small"
          label="Pipeline"
          onChange={onTypeChange}>
          <MenuItem value="hangup">Hangup</MenuItem>
          <MenuItem value="transfer">Transfer</MenuItem>
        </Select>
      </FormControl>
      {pipelineType === 'transfer' && (
        <>
          <Box sx={{width: '75%', mt: 2}}>
            <VoiceBotTextFieldField
              form={form}
              path={`${path}.transfer.context`}
              label="Context"
              rules={{required: true}}
              disabled={isSubmitting}
            />
          </Box>
          <Box sx={{width: '75%', mt: 2}}>
            <VoiceBotTextFieldField
              form={form}
              path={`${path}.transfer.extension`}
              label="Extension"
              rules={{required: true}}
              disabled={isSubmitting}
            />
          </Box>
        </>
      )}
    </Box>
  );
}
