import React, {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';

import {Box, Breadcrumbs, Typography} from '@mui/material';

import {CreateVoiceBotCommand, CreateVoiceBotInput} from '@wildix/wim-voicebots-client';

import getWimVoiceBotsClient from '../../../base/helpers/getWimVoiceBotsClient';
import VoiceBotsIcon from '../../../base/icons/VoiceBotsIcon';
import {VOICEBOTS_TITLE} from '../../../texts';
import {VoiceBotsPath} from '../constants';
import normalizeVoiceBotFormEndpoint from '../helpers/normalizeVoiceBotFormEndpoint';
import {BotFormData} from '../types';
import VoiceBotForm from './VoiceBotForm';

export default function VoiceBotAddScreen() {
  const navigate = useNavigate();
  const form = useForm<BotFormData>({
    defaultValues: {
      name: '',
      endpoint: {
        llm: {
          prompt: '',
        },
      },
    },
  });
  const {setError} = form;

  const onSubmit = useCallback(
    async (data: BotFormData) => {
      const {name, message, endpoint, pipeline} = data;
      const client = getWimVoiceBotsClient();
      const content: CreateVoiceBotInput = {
        name,
        message,
        endpoint: normalizeVoiceBotFormEndpoint(endpoint),
        pipeline,
      };

      try {
        await client.send(new CreateVoiceBotCommand(content));
        navigate(VoiceBotsPath.base);
      } catch (error) {
        // @ts-ignore
        setError('name', {message: error.toString()});
      }
    },
    [navigate],
  );

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={VoiceBotsPath.base}>
          {VOICEBOTS_TITLE}
        </Typography>
        <Typography color="text.primary">Add</Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '842px'}}>
        <Box width="96px">
          <VoiceBotsIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5} pb={8}>
          <Typography color="text.primary" variant="h5">
            New Voice Bot
          </Typography>

          <VoiceBotForm form={form} onSubmit={onSubmit} />
        </Box>
      </Box>
    </Box>
  );
}
