import {IntegrationService} from '../../base/types';

interface GoogleInstallData {
  administrator: string;
  domain: string;
}

interface Settings {
  settings: {
    hidePublicEventSummary: boolean;
  };
}
export interface GoogleCalendarInstallProps {
  service: IntegrationService.GOOGLE_CALENDAR;
  data: GoogleInstallData;
}

export interface GoogleCalendarUpdateProps {
  integrationId: string;
  status?: 'enabled' | 'disabled';
  data?: Settings;
}

export enum GoogleIntegrationPath {
  base = '/google/calendar',
  install = '/google/calendar/install',
}
