import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, Checkbox, Chip, FormControlLabel, styled} from '@mui/material';

import {WebhookEvent, WebhookFormData} from '../../types';

const DeprecatedChip = styled(Chip)({
  fontSize: '12px',
  color: 'black',
  height: '18px',
  backgroundColor: '#DCE775',
});

interface EventsAccordionItemProps {
  event: WebhookEvent;
  form: UseFormReturn<WebhookFormData>;
}

export default function WebhookEventsAccordionItem(props: EventsAccordionItemProps) {
  const {event} = props;
  const {key, deprecated, description} = event;
  const {form} = props;
  const {formState, watch, setValue} = form;
  const {isSubmitting, errors} = formState;

  const events = watch('events');
  const checked = events.includes(event.key);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
    if (checked !== newChecked) {
      if (newChecked) {
        setValue('events', [...events, key]);
      } else {
        setValue(
          'events',
          events.filter((eventKey) => eventKey !== key),
        );
      }
    }
  };

  return (
    <Box display="flex" alignItems="center" pl={6} borderTop="1px solid rgba(0, 0, 0, 0.12)">
      <Box sx={{width: '300px', display: 'flex', alignItems: 'center'}}>
        <FormControlLabel
          label={key}
          componentsProps={{typography: {sx: {fontSize: '13px'}}}}
          control={<Checkbox disabled={isSubmitting} checked={checked} onChange={onChange} />}
        />
        {deprecated && <DeprecatedChip label="deprecated" size="small" color="primary" variant="filled" />}
      </Box>
      {description && (
        <Box sx={{flex: '1', fontSize: '13px', pt: 1, pr: 1, pb: 1, color: '#757679'}}>{description}</Box>
      )}
    </Box>
  );
}
