import React, {useId} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, Typography} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';

import {BotFormData} from '../../../types';

interface TransferVariantField {
  form: UseFormReturn<BotFormData>;
  path: string;
}

export enum VariantTypes {
  PREDEFINED = 'predefined',
  DYNAMIC = 'dynamic',
  DIRECTORY = 'directory',
}

export default function TransferVariantField(props: TransferVariantField) {
  const {form, path} = props;
  const id = useId();
  const {control, watch, formState, trigger, setValue} = form;
  const {errors, isSubmitting} = formState;
  const value = watch(path as any) || VariantTypes.PREDEFINED;

  const onVariantChange = (event: SelectChangeEvent) => {
    setValue(path as any, event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel size="small" id={`${id}-select-label`}>
        Destination
      </InputLabel>
      <Select
        labelId={`${id}-select-label`}
        value={value}
        disabled={isSubmitting}
        size="small"
        label="Destination"
        onChange={onVariantChange}>
        <MenuItem value={VariantTypes.PREDEFINED}>
          <Box sx={{width: '480px', overflow: 'visible', whiteSpace: 'break-spaces'}}>
            <div>Predefined</div>
            <Box>
              <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>
                The specified extension number would be used to make a transfer.
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        <MenuItem value={VariantTypes.DYNAMIC}>
          <Box sx={{width: '480px', overflow: 'visible', whiteSpace: 'break-spaces'}}>
            <div>Dynamic</div>
            <Box>
              <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>
                The AI would determine an extension number on its own using context and provided instructions.
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        <MenuItem value={VariantTypes.DIRECTORY}>
          <Box sx={{width: '480px', overflow: 'visible', whiteSpace: 'break-spaces'}}>
            <div>Directory</div>
            <Box>
              <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>
                The AI would utilize the Organization Directory to identify which extension number to use for the
                transfer.
              </Typography>
            </Box>
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  );
}
